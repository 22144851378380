<template>
  <div class="row-history">
    <component :is="report" :partcurrent="partcurrent" :downspeed="downspeed" :upspeed="upspeed" :ashReport="ashReport" :selectItem="selectItem" :checkedData="checkedData" :depthReport="depthReport" :pulpReport="pulpReport"></component>
    <a id="simulation" href=""></a>
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
                <el-button type="primary" @click="showData()">选择参数</el-button>
              </div>
              <div class="left">
                <!-- <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item> -->
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="pileDescribe"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <!-- 表格数据 -->
      <el-main class="m-table-main">
        <div id="table-content">
          <el-table class="jbThead" :cell-style="backgroundColor" :data="tableData" style="width: 100%" ref="table" align='center' @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" align='center'></el-table-column>
            <el-table-column v-for="(col,index) in cols" :key="index" :prop="col.prop" :label="col.label" :formatter="col.formatter" min-width='145px' align='center' show-overflow-tooltip></el-table-column>
            <!-- <el-table-column width="70" align='center' property="status" label="状态">
            </el-table-column> -->
            <el-table-column fixed="right" width="60" label="操作">
              <template slot-scope="scope">
                <div class="m-edit-btns">
                  <div class="item">
                    <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
      <!-- 选择参数弹出框 -->
      <el-dialog title="选择需要展示的参数" :visible.sync="dialogFormVisible" width="40%" center top="15vh">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选
        </el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedFileds" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in fieldNames" :label="item.prop" :key="item.prop">{{
                            item.label
                        }}
          </el-checkbox>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
          <el-button type="primary" @click="confirmShow" size="medium">确定</el-button>
        </div>
      </el-dialog>

      <!-- 弹出框 -->
      <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
        <div slot="title" class="dialog-header">
          <div class="left">
            <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
          </div>
          <div class="right">
            <el-dropdown trigger="click" placement="top-start" @command="downLoad">
              <span class="more ">报表下载
              </span>
              <el-dropdown-menu slot="dropdown" class="g-dropdown">
                <el-dropdown-item :command="0">
                  <span class="icon icon01"></span>
                  <span class="name">打印版</span>
                </el-dropdown-item>
                <el-dropdown-item :command="1">
                  <span class="icon icon02"></span>
                  <span class="name">综合版</span>
                </el-dropdown-item>
                <el-dropdown-item :command="2">
                  <span class="icon icon03"></span>
                  <span class="name">详细版</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="dialog-content">
          <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
            <div class="m-table-detail">
              <!-- 详细数据 -->
              <div class="detailitem">
                <h3>详细数据</h3>
                <div class="box">
                  <ul class="ul-detail">
                    <li class="s1">
                      <div class="inner">
                        <div class="item">
                          <div class="tit">桩号</div>
                          <div class="value valuepile" :title=this.selectItem.pileDescribe>
                            {{ selectItem.pileDescribe }}
                          </div>
                        </div>
                        <div class="item">
                          <div class="tit">桩机编号</div>
                          <div class="value">{{ selectItem.deviceKey }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">开始时间</div>
                          <div class="value">{{ selectItem.beginTime|datafilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">结束时间</div>
                          <div class="value">{{ selectItem.endTime|datafilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">施工桩长</div>
                          <div class="value">{{ selectItem.depth|numfilters }}(m)</div>
                        </div>
                      </div>
                    </li>
                    <li class="s2">
                      <div class="inner">
                        <div class="item">
                          <div class="tit">初搅深度(m)</div>
                          <div class="value">{{ selectItem.frDepth|numfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">累计浆量(L)</div>
                          <div class="value">{{ selectItem.cumulativeAsh|numfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">累计灰量(kg)</div>
                          <div class="value">{{ selectItem.cumulativeAsh|numfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">平均浆量(L/m)</div>
                          <div class="value">{{ selectItem.averagePulp|numfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">平均灰量(kg/m)</div>
                          <div class="value">{{ selectItem.averageAsh|numfilters }}</div>
                        </div>
                      </div>
                    </li>
                    <li class="s3">
                      <div class="inner">
                        <div class="item">
                          <div class="tit">平均密度(g/cm³)</div>
                          <div class="value">{{ selectItem.waterCementRatio|densityfilter }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">成桩时间(min)</div>
                          <div class="value">{{ selectItem.pileTime|minfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">复搅深度(m)</div>
                          <div class="value">{{ selectItem.reDepth|numfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">下钻速度(cm/min)</div>
                          <div class="value">{{ selectItem.downSpeed|numfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">提钻速度(cm/min)</div>
                          <div class="value">{{ selectItem.upSpeed|numfilters }}</div>
                        </div>
                      </div>
                    </li>
                    <li class="s4">
                      <div class="inner">
                        <div class="item">
                          <div class="tit">桩端电流(A)</div>
                          <div class="value">{{ selectItem.pileTopCurrent|numfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">最大电流(A)</div>
                          <div class="value">{{ selectItem.maxCurrent|numfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">空搅深度(m)</div>
                          <div class="value">{{ selectItem.emDepth|numfilters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">最大斜度(%)</div>
                          <div class="value">{{ selectItem.xAngle|num1filters }}</div>
                        </div>
                        <div class="item">
                          <div class="tit">喷浆压力(Mpa)</div>
                          <div class="value">{{ selectItem.averagePressure|numfilters }}</div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- 段数据 -->
              <br />
              <h3>段数据列表</h3>
              <el-table ref="saveTable" class="m-table-save" :data="selectItem.data" style="width: 100%;margin-top:20px" height="28em">
                <el-table-column property="partId" label="序号" min-width="8.33%" align='center'>
                  <!-- <template slot-scope="scope" >{{ scope.$index + 1 }}</template> -->
                </el-table-column>
                <el-table-column property="partDeep" label="段深度(m)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partDeep|numfilters }}
                  </template>
                </el-table-column>
                <el-table-column property="partPulp" label="段浆量(L)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partPulp|numfilters }}
                  </template>
                </el-table-column>
                <el-table-column property="partAsh" label="段灰量(kg)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partAsh|numfilters }}
                  </template>
                </el-table-column>
                <el-table-column property="partCurrent" label="段电流(A)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partCurrent|numfilters }}
                  </template>
                </el-table-column>
                <el-table-column property="partDensity" label="段密度(g/cm³)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partDensity|num2filters }}
                  </template>
                </el-table-column>
                <el-table-column property="partDownSpeed" label="速度(cm/min)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partDownSpeed|num3filters }}
                  </template>
                </el-table-column>
                <el-table-column property="partPressure" label="段喷压(MPa)" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partPressure|numfilters }}
                  </template>
                </el-table-column>
                <el-table-column property="partTime" label="段时长(s)" min-width="8.33%" align='center'>
                </el-table-column>
                <el-table-column property="partDownSpeed" label="状态" min-width="8.33%" align='center'>
                  <template slot-scope="scope">
                    {{ scope.row.partDownSpeed|num5filters }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 图标曲线 -->
          <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
            <div class="m-pop-echart">
              <el-row class="row">
                <el-col :span="12" class="col">
                  <div class="inner">
                    <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                  </div>
                </el-col>
                <el-col :span="12" class="col">
                  <div class="inner">
                    <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                  </div>
                </el-col>
              </el-row>
              <el-row class="row">
                <el-col :span="12" class="col">
                  <div class="inner">
                    <div ref="myChart03" :style="{ width: '100%', height: '100%' }"></div>
                  </div>
                </el-col>
                <el-col :span="12" class="col">
                  <div class="inner">
                    <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-dialog>

    </el-container>

    <el-footer class="m-selectAll">
      <div class="left">
        <div class="item">
          <el-checkbox v-model="totalChecked" class="total" @change="totalChange">全选
          </el-checkbox>
        </div>
        <div class="item">
          <a class="export" @click="checkall">导出</a>
        </div>
      </div>
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40]" :page-size="pageSize" layout="slot, prev, pager,next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>

        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 40]" :page-size="pageSize" layout="slot, jumper, sizes" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import report from "../report/report.vue"
import { http, show } from '../../main'
import moment from "moment";
import { historyWarns, queryDevices } from "@/api/api"
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";

const allField = ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'depth', 'frDepth', 'reDepth', 'waterCementRatio',
  'cumulativePulp', 'averagePulp', 'cumulativeAsh', 'averageAsh', 'pileTopCurrent', 'maxCurrent',
  'downSpeed', 'upSpeed', 'averageCurrent', 'maxDownSpeed', 'maxUpSpeed', 'xAngle', 'pileTime',
  'tTypeLength', 'tTypePulp', 'tTypeAsh', 'bottomPartPulp', 'bottomPartAsh', 'emDepth', 'warnreason'] //所有的数据
export default {
  components: {
    gTabs, report
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'toLastPage', 'toFirstPage', 'handleCurrentChange'],
  data() {
    return {
      report: 'report',
      totalChecked: false,
      checkAll: false,  //默认全选
      isIndeterminate: false,   //默认全选
      dialogFormVisible: false,
      centerDialogVisible: false,// 弹窗
      // 查询数据
      pileDescribe: '',
      deviceKey: '',
      inquire: [],
      totalNum: 0,
      // 查询数据
      checkedId: [], //存储点击后导出的id
      checkedFileds: ['pileDescribe', 'deviceKey', 'beginTime', 'depth', 'cumulativeAsh', 'upSpeed', 'downSpeed', 'warnreason'], //默认全选所有的数据,选中要显示的数据
      fieldNames: [{
        prop: 'pileDescribe',
        label: '桩号'
      },
      {
        prop: 'deviceKey',
        label: '设备编号'
      },
      {
        prop: 'beginTime',
        label: '开始时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'endTime',
        label: '结束时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'depth',
        label: '施工桩长(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'frDepth',
        label: '初搅深度(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'reDepth',
        label: '复搅深度(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'waterCementRatio',
        label: '水灰比',
        formatter: this.numFormatter
      },
      {
        prop: 'cumulativePulp',
        label: '累计浆量(L)',
        formatter: this.numFormatter
      },
      {
        prop: 'averagePulp',
        label: '平均浆量(L/m)',
        formatter: this.numFormatter
      },
      {
        prop: 'cumulativeAsh',
        label: '累计灰量(kg)',
        formatter: this.numFormatter
      },
      {
        prop: 'averageAsh',
        label: '平均灰量(kg/m)',
        formatter: this.numFormatter
      },
      {
        prop: 'pileTopCurrent',
        label: '桩端电流(A)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxCurrent',
        label: '最大电流(A)',
        formatter: this.numFormatter
      },
      {
        prop: 'downSpeed',
        label: '下钻速度(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'upSpeed',
        label: '提钻速度(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'averageCurrent',
        label: '平均电流(A)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxDownSpeed',
        label: '最大钻速(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxUpSpeed',
        label: '最大提速(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'xAngle',
        label: '最大斜度(%)',
        formatter: this.num1Formatter
      },

      {
        prop: 'pileTime',
        label: '成桩时间(min)	',
        formatter: this.minFormatter
      },
      {
        prop: 'tTypeLength',
        label: '扩大头桩长(m)	',
        formatter: this.numFormatter
      },
      {
        prop: 'tTypePulp',
        label: '扩大头浆量(L)',
        formatter: this.numFormatter
      },
      {
        prop: 'tTypeAsh',
        label: '扩大头灰量(kg)',
        formatter: this.numFormatter
      },
      {
        prop: 'bottomPartPulp',
        label: '下部桩浆量(L)',
        formatter: this.numFormatter
      },
      {
        prop: 'bottomPartAsh',
        label: '下部桩灰量(kg)',
        formatter: this.numFormatter
      },
      {
        prop: 'emDepth',
        label: '空搅深度(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'warnreason',
        label: '预警原因',
      }
      ],   //table列表对象数组全部的数据
      cols: [
        {
          prop: 'pileDescribe',
          label: '桩号'
        },
        {
          prop: 'deviceKey',
          label: '设备编号'
        },
        {
          prop: 'beginTime',
          label: '开始时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'depth',
          label: '桩长(m)',
          formatter: this.numFormatter
        },
        {
          prop: 'cumulativeAsh',
          label: '累计灰量(kg)',
          formatter: this.numFormatter
        }, {
          prop: 'upSpeed',
          label: '提钻速度(cm/min)',
          formatter: this.numFormatter
        }, {
          prop: 'downSpeed',
          label: '下钻速度(cm/min)',
          formatter: this.numFormatter
        }, {
          prop: 'warnreason',
          label: '预警原因',
        },
        // {
        //   prop: 'status',
        //   label: '状态'
        // }
      ],//table表格默认显示数据
      tempCols: [],  //选择参数临时的数据容器
      data: [],
      selectItem: [],//详情数据
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        /* {
                  name: "段数据列表",
                }, */
        {
          name: "图表曲线",
        },
      ],
      // 导出数据
      checkedData: [],
      depthReport: [],
      pulpReport: {},
      ashReport: {},
      upspeed: {},
      downspeed: {},
      partcurrent: {},
      // 导出数据
      echartsAll: [] // 图片下载
    }
  },

  mounted() {
    this.deviceKey = this.device
  },
  filters: {
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val) {
      return parseFloat(val).toFixed(2)
    },
    minfilters(val) {
      return (parseFloat(val) / 60).toFixed(2)
    },
    num1filters(val) {
      return parseFloat(val).toFixed(2)
    },
    num2filters(val) {
      return parseFloat(val).toFixed(3)
    },
    num3filters(val) {
      return Math.abs(parseFloat(val).toFixed(2))
    },
    num5filters(val) {
      return val < 0 ? '提钻' : '下钻'
    },
    densityfilter(val) {
      if (val == 0) {
        return val
      } else {
        return parseFloat(1 + 1 / (3 * val + 1) * 2).toFixed(3)
      }
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.tab2 = 0
        this.selectItem = []
        this.data = []
        this.upspeed = []
        this.downspeed = []
        this.ashReport = []
        this.depthReport = []
        this.partcurrent = []
        if (this.myChart01 == undefined) return
        this.myChart01.dispose()
        this.myChart02.dispose()
        this.myChart03.dispose()
        this.myChart04.dispose()
      }
    }
  },
  methods: {
    // 设置列的背景颜色
    backgroundColor(row, column, rowIndex, columnIndex) {
      if (row.column.property == "upSpeed") {
        if (row.row.upSpeedError >= 0.3) {
          return { color: '#ff3333' }
        }
        if (row.row.upSpeedError < 0.3 && row.row.upSpeedError > 0) {
          return { color: '#DAA520' }
        }
      }
      if (row.column.property == "cumulativeAsh") {
        if (row.row.ashError >= 0.3) {
          return { color: '#ff3333' }
        }
        if (row.row.ashError < 0.3 && row.row.ashError > 0) {
          return { color: '#DAA520' }
        }
      }
      if (row.column.property == "downSpeed") {
        if (row.row.downSpeedError >= 0.3) {
          return { color: '#ff3333' }
        }
        if (row.row.downSpeedError < 0.3 && row.row.downSpeedError > 0) {
          return { color: '#DAA520' }
        }
      }
      if (row.column.property == "depth") {
        if (row.row.depthError >= 0.3) {
          return { color: '#ff3333' }
        }
        if (row.row.depthError < 0.3 && row.row.depthError > 0) {
          return { color: '#DAA520' }
        }
      }
    },
    // 详情报表导出
    downLoad(command) {
      switch (command) {
        case 0: {
          this.tableToExcel('statisticsTablePrint', moment(this.selectItem.beginTime * 1000).format('YYYY-MM-DD') + '-搅拌桩-打印版')
          break
        }
        case 1: {
          this.tableToExcel('statisticsTable', moment(this.selectItem.beginTime * 1000).format('YYYY-MM-DD') + '-搅拌桩-综合版')
          break
        }
        case 2: {
          this.tableToExcel('statisticsTabledetailed', moment(this.selectItem.beginTime * 1000).format('YYYY-MM-DD') + '-搅拌桩_详细版')
          break
        }
      }
    },
    // 全选导出
    checkall() {
      this.tableToExcel('statisticsTableRecord', '-搅拌桩-统计报表')
    },
    //表格导出的封装
    tableToExcel(tableid, sheetName) {
      let uri = 'data:application/vnd.ms-excel;base64,';
      let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"' +
        'xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
        + '<x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets>'
        + '</x:ExcelWorkbook></xml><![endif]-->' +
        ' <style type="text/css">' +
        'table td {' +
        'height: 25px;' +
        'font-family: 宋体;' +
        'font-size: 13px;' +
        'text-align: left;' +
        'border: 0.5px solid #000000;' +
        ' }' +
        'table .tHead {' +
        'font-size: 20px;' +
        'text-align:center;' +
        'font-weight: bold;' +
        'height: 40px;' +
        ' }' +
        'table tfoot tr{' +
        'height: 25px;' +
        ' }' +
        'table tbody td{' +
        'text-align: center;' +
        'height: 20px;' +
        'mso-number-format:"@";' +
        ' }' +
        '</style>' +
        '</head><body ><table class="excelTable">{table}</table></body></html>';
      if (!tableid.nodeType) tableid = document.getElementById(tableid);
      let ctx;
      if (tableid) {
        ctx = { worksheet: sheetName || 'Worksheet', table: tableid.innerHTML };
        document.getElementById('simulation').href = uri + this.base64(this.format(template, ctx));
        document.getElementById('simulation').download = sheetName + ".xls";//自定义文件名
        document.getElementById('simulation').click();
      }
    },
    base64(s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format(s, c) {
      return s.replace(/{(\w+)}/g,
        function (m, p) {
          return c[p];
        });
    },

    // 设备查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      historyWarns('JBZ', 0, this.deviceKey, this.pageSize, this.pileDescribe).then((res) => {
        this.inquire = res.content
        this.totalNum = res.totalElements
        for (let i = 0; i < this.inquire.length; i++) {
          this.inquire[i].warnreason = ''
          if (this.inquire[i].depthError > 0) {
            this.inquire[i].warnreason += '桩长不足:' + (this.inquire[i].depthError * 100).toFixed(2) + '%;'
          }
          if (this.inquire[i].ashError > 0) {
            this.inquire[i].warnreason += '灰量不足:' + (this.inquire[i].ashError * 100).toFixed(2) + '%;'
          }
          if (this.inquire[i].upSpeedError > 0) {
            this.inquire[i].warnreason += '提速超标:' + (this.inquire[i].upSpeedError * 100).toFixed(2) + '%;'
          }
          if (this.inquire[i].downSpeedError > 0) {
            this.inquire[i].warnreason += '钻速超标:' + (this.inquire[i].downSpeedError * 100).toFixed(2) + '%'
          }
        }
        this.$emit('changeDevicekey', this.deviceKey, this.pileDescribe, this.inquire, this.totalNum)
        loading.close();
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    // 重置
    resetClick() {
      this.deviceKey = ""
      this.pileDescribe = ""
    },

    testFormatter(row, column, cellValue, index) {
      return moment(cellValue * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2)
    },
    minFormatter(row, column, cellValue, index) {
      return (parseFloat(cellValue) / 60).toFixed(0)
    },
    num1Formatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2) + '%'
    },
    // 详情切换标签
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index === 1) {
        this.$nextTick(() => {
          this.initEchart01();
          this.initEchart02();
          this.initEchart03();
          echarts.connect(this.echartsAll)
          this.initEchart04();
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
            self.myChart03.resize();
            self.myChart04.resize();
          };
        });
      }
    },
    initEchart01() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDensity.toFixed(3) + ''];
      });
      let option = {
        title: {
          text: "深度、密度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "密度"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度          ",
            type: "value",
            inverse: true,
            nameLocation: "end",
            axisLine: { //设置轴线
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "          密度",
            nameLocation: "end", // 设置坐标轴名称显示位置
            inverse: true,
            type: "value",
            splitLine: { //网格不显示
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "密度",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart01.setOption(option);
      this.echartsAll.push(this.myChart01)
    },
    initEchart02() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partCurrent.toFixed(2)];
      });
      let option = {
        title: {
          text: "钻杆深度电流曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "电流"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度       ",
            type: "value",
            inverse: true, //设置坐标轴翻转
            nameLocation: 'end',
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "      电流",
            type: "value",
            nameLocation: 'start',
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "电流",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart02.setOption(option);
      this.echartsAll.push(this.myChart02)
    },
    initEchart03() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp / item.partTime * 60).toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDownSpeed.toFixed(2)];
      });
      let option = {
        title: {
          text: "流量、速度变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["流量", "速度"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "流量(L/min)",
            type: "value",
            nameLocation: 'start',
            axisLine: { //设置坐标轴是否显示
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            max: 100
          },
          {
            name: "速度(cm/min)      ",
            type: "value",
            nameLocation: 'start',
            splitLine: { //网格线
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "流量",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "速度",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart03 = echarts.init(this.$refs.myChart03, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart03.setOption(option);
      this.echartsAll.push(this.myChart03)
    },
    initEchart04() {
      var ash = []
      for (var j = 0; j < this.selectItem.data.length; j++) {
        if (ash[Math.ceil(this.selectItem.data[j].partDeep - 1)] == undefined) {
          ash[Math.ceil(this.selectItem.data[j].partDeep - 1)] = 0
        }
        ash[Math.ceil(this.selectItem.data[j].partDeep - 1)] += this.selectItem.data[j].partAsh
      }
      var numbers2 = ash.map(myFunction);

      function myFunction(value, index, array) {
        var data = {
          value: Number(value.toFixed(2)),
          name: index + 1
        }
        return data;
      }

      let option = {
        title: {
          text: "成桩灰量分布",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} : {c}",
        },
        grid: {
          top: "10%",
          bottom: "10%",
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {},
        //   },
        // },
        series: [
          {
            name: "成桩灰量分布",
            type: "funnel",
            width: "50%",
            left: "center",
            top: "15%",
            bottom: "8%",
            label: {
              show: true,
            },
            labelLine: {
              show: true,
            },
            data: numbers2,
            sort: "none",
            color: '#5470c6'
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart04 = echarts.init(this.$refs.myChart04, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart04.setOption(option);
    },
    // 点击详情 详情弹出框
    handleCommand(index) {
      this.selectItem = this.tableData[index]
      this.centerDialogVisible = true;
      var day = this.selectItem.beginTime
      var time
      var pulp25 = []
      var pulp50 = []
      var pulp75 = []
      var pulp100 = []
      var num = []
      var current = []
      let map = new Map();
      let maps = new Map();
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        day += this.tableData[index].data[i].partTime
        time = moment(day * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.data.push(time)

        if (this.tableData[index].data[i].partDeep != 0) {
          this.depthReport.push(Math.ceil(this.tableData[index].data[i].partDeep))
        }
        // 数组去重   
        for (var k = 0; k < this.depthReport.length; k++) {
          for (var j = k + 1; j < this.depthReport.length; j++) {
            if (this.depthReport[k] == this.depthReport[j]) {//第一个等同于第二个，splice方法删除第二个
              this.depthReport.splice(j, 1);
              j--;
            }
          }
        }
        if (this.tableData[index].data[i].partDeep == Math.floor(this.tableData[index].data[i].partDeep) + 0.25) {
          let obj = { key: Math.floor(this.tableData[index].data[i].partDeep) + 0.25, value: Number(this.tableData[index].data[i].partPulp) }
          pulp25.push(obj)
          var result = {};
          pulp25.forEach(item => {
            if (result[item.key]) {
              result[item.key] += item.value;
            } else {
              result[item.key] = item.value;
            }
          })
        }
        if (this.tableData[index].data[i].partDeep == Math.floor(this.tableData[index].data[i].partDeep) + 0.5) {
          let obj1 = { 'key': Math.floor(this.tableData[index].data[i].partDeep) + 0.5, 'value': Number(this.tableData[index].data[i].partPulp.toFixed(2)) }
          pulp50.push(obj1)
          var results = {};
          pulp50.forEach(item => {
            if (results[item.key]) {
              results[item.key] += item.value;
            } else {
              results[item.key] = item.value;
            }
          })
        }
        if (this.tableData[index].data[i].partDeep == Math.floor(this.tableData[index].data[i].partDeep) + 0.75) {
          let obj2 = { 'key': Math.floor(this.tableData[index].data[i].partDeep) + 0.75, 'value': Number(this.tableData[index].data[i].partPulp.toFixed(2)) }
          pulp75.push(obj2)
          var resultss = {};
          pulp75.forEach(item => {
            if (resultss[item.key]) {
              resultss[item.key] += item.value;
            } else {
              resultss[item.key] = item.value;
            }
          })
        }
        if (this.tableData[index].data[i].partDeep == Math.floor(this.tableData[index].data[i].partDeep)) {
          let obj3 = { 'key': Math.floor(this.tableData[index].data[i].partDeep), 'value': Number((this.tableData[index].data[i].partPulp).toFixed(2)) }
          pulp100.push(obj3)
          var result100 = {};
          pulp100.forEach(item => {
            if (result100[item.key]) {
              result100[item.key] += item.value;
            } else {
              result100[item.key] = item.value;
            }
          })
        }
        // 段灰量数据
        if (num[Math.ceil(this.tableData[index].data[i].partDeep - 1)] == undefined) {
          num[Math.ceil(this.tableData[index].data[i].partDeep - 1)] = 0
        }
        num[Math.ceil(this.tableData[index].data[i].partDeep - 1)] += Number(this.tableData[index].data[i].partAsh.toFixed(2))
        // 段电流数据
        if (current[Math.ceil(this.tableData[index].data[i].partDeep - 1)] == undefined) {
          current[Math.ceil(this.tableData[index].data[i].partDeep - 1)] = 0
        }
        current[Math.ceil(this.tableData[index].data[i].partDeep - 1)] = Number(this.tableData[index].data[i].partCurrent.toFixed(2))
        // 下钻速度 
        if (this.tableData[index].data[i].partDeep > 0 && this.tableData[index].data[i].partDownSpeed > 0) {
          if (map.get(Math.ceil(this.tableData[index].data[i].partDeep)) == undefined) {
            let deeep = []
            deeep.push(this.tableData[index].data[i].partDownSpeed)
            map.set(Math.ceil(this.tableData[index].data[i].partDeep), deeep)
          } else {
            let deeep = map.get(Math.ceil(this.tableData[index].data[i].partDeep))
            deeep.push(this.tableData[index].data[i].partDownSpeed)
            map.set(Math.ceil(this.tableData[index].data[i].partDeep), deeep)
          }
        }
        // 提钻
        if (this.tableData[index].data[i].partDownSpeed < 0) {
          if (maps.get(Math.ceil(this.tableData[index].data[i].partDeep)) == undefined) {
            let deeep = []
            deeep.push(this.tableData[index].data[i].partDownSpeed)
            maps.set(Math.ceil(this.tableData[index].data[i].partDeep), deeep)
          } else {
            let deeep = maps.get(Math.ceil(this.tableData[index].data[i].partDeep))
            deeep.push(this.tableData[index].data[i].partDownSpeed)
            maps.set(Math.ceil(this.tableData[index].data[i].partDeep), deeep)
          }
        }
      }
      var currents = []
      for (let a = 0; a < current.length; a++) {
        let obj = {
          key: a,
          value: current[a]
        }
        currents.push(obj)
      }
      currents.forEach(item => {
        if (this.partcurrent[item.key]) {
          this.partcurrent[item.key] += item.value;
        } else {
          this.partcurrent[item.key] = item.value;
        }
      })
      // 提钻速度
      var upspeed = []
      maps.forEach(function (key) {
        upspeed.push(key)
      })
      var averageupSpeed = []
      var upnum = 0
      for (let a = 0; a < upspeed.length; a++) {
        for (let s = 0; s < upspeed[a].length; s++) {
          upnum += upspeed[a][s]
        }
        let averageup = Math.abs((upnum / upspeed[a].length).toFixed(2))
        averageupSpeed.push(averageup)
        upnum = 0
      }
      averageupSpeed = averageupSpeed.reverse()
      var up = []
      for (let a = 0; a < averageupSpeed.length; a++) {
        let obj = {
          key: a,
          value: averageupSpeed[a]
        }
        up.push(obj)
      }
      up.forEach(item => {
        if (this.upspeed[item.key]) {
          this.upspeed[item.key] += item.value;
        } else {
          this.upspeed[item.key] = item.value;
        }
      })
      // 下钻速度
      var downdeep = []
      map.forEach(function (key) {
        downdeep.push(key)
      })
      var averagedownSpeed = []
      var nums = 0
      for (let a = 0; a < downdeep.length; a++) {
        for (let s = 0; s < downdeep[a].length; s++) {
          nums += downdeep[a][s]
        }
        let average = Number((nums / downdeep[a].length).toFixed(2))
        averagedownSpeed.push(average)
        nums = 0
      }
      var down = []
      for (let a = 0; a < averagedownSpeed.length; a++) {
        let obj = {
          key: a,
          value: averagedownSpeed[a]
        }
        down.push(obj)
      }
      down.forEach(item => {
        if (this.downspeed[item.key]) {
          this.downspeed[item.key] += item.value;
        } else {
          this.downspeed[item.key] = item.value;
        }
      })
      // 段灰量
      var ash = [];
      for (let k = 0; k < num.length; k++) {
        let obj = {
          key: k,
          value: num[k]
        }
        ash.push(obj)
      }
      ash.forEach(item => {
        if (this.ashReport[item.key]) {
          this.ashReport[item.key] += item.value;
        } else {
          this.ashReport[item.key] = item.value;
        }
      })
      this.depthReport.unshift(0)
      this.depthReport.splice(this.depthReport.length - 1, 1)
      this.pulpReport = Object.assign(result, results, resultss, result100)

    },

    //全选按钮 选择参数显示数据
    handleCheckAllChange(val) {
      this.checkedFileds = val ? allField : [];
      this.isIndeterminate = false;
    },
    //单个选中
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fieldNames.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldNames.length;
    },
    showData(row) {
      this.dialogFormVisible = true;
    },
    confirmShow() {
      this.tempCols = [];
      if (this.checkedFileds.length < 1) {
        this.$message.warning("请至少选择一项数据");
        this.dialogFormVisible = true;
      } else {
        for (var i = 0; i < this.fieldNames.length; i++) {
          for (var j = 0; j < this.checkedFileds.length; j++) {
            if (this.fieldNames[i].prop == this.checkedFileds[j]) {
              this.tempCols.push(this.fieldNames[i]);
            }
          }
        }
        this.cols = this.tempCols;
        this.dialogFormVisible = false;
      }
    },
    // 选中发生变化 全选选择框 全选列表导出
    selectionChange(e) {
      this.checkedData = e.reverse()
      var data = []
      for (let i = 0; i < e.length; i++) {
        data.push(e[i].data)
      }
      var firstdown = []
      var firstDownspeed = [] //所有的下钻
      var firstupSpeed = [] //所有的提钻
      var firstup = []
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
          if (data[i][j].partDownSpeed > 0) {
            firstdown.push([data[i][j].partDeep, data[i][j].partTime, data[i][j].partDownSpeed])
          }
          if (data[i][j].partDownSpeed < 0) {
            firstup.push([data[i][j].partDeep, data[i][j].partTime, data[i][j].partDownSpeed])
          }
        }
        firstDownspeed.push(firstdown)
        firstupSpeed.push(firstup)
        firstup = []
        firstdown = []
      }
      var a = []
      for (let i = 0; i < firstDownspeed.length; i++) {
        for (let k = 1; k < firstDownspeed[i].length; k++) {
          if (firstDownspeed[i][k - 1][0] > firstDownspeed[i][k][0]) {
            var d = k
          }
        }
        if (d == 0 || d == undefined) {
          d = firstDownspeed[i].length
        }
        a.push(d)
        d = 0
      }
      var speedone = [] //第一次下钻
      var speedtwo = [] //第二次下钻
      for (let j = 0; j < firstDownspeed.length; j++) {
        speedone.push(firstDownspeed[j].slice(0, a[j]))
        speedtwo.push(firstDownspeed[j].slice(a[j]))
      }
      // 第一次下钻速度
      var time1 = 0
      var total1 = 0
      for (let i = 0; i < speedone.length; i++) {
        for (let j = 0; j < speedone[i].length; j++) {
          time1 += speedone[i][j][1]
          total1 += speedone[i][j][1] * speedone[i][j][2]
        }
        if (total1 / time1 == "Infinity" || speedone[i].length == 0) {
          this.checkedData[i].speed1 = 0
        } else {
          this.checkedData[i].speed1 = (total1 / time1).toFixed(2)
        }
        time1 = 0
        total1 = 0
      }
      // 第二次下钻速度
      var time2 = 0
      var total2 = 0
      for (let i = 0; i < speedtwo.length; i++) {
        for (let j = 0; j < speedtwo[i].length; j++) {
          time2 += speedtwo[i][j][1]
          total2 += speedtwo[i][j][1] * speedtwo[i][j][2]
        }
        if (total2 / time2 == "Infinity" || speedtwo[i].length == 0) {
          this.checkedData[i].speed2 = 0
        } else {
          this.checkedData[i].speed2 = (total2 / time2).toFixed(2)
        }
        time2 = 0
        total2 = 0
      }
      // 提钻速度
      var b = []
      for (let i = 0; i < firstupSpeed.length; i++) {
        for (let k = 1; k < firstupSpeed[i].length; k++) {
          if (firstupSpeed[i][k - 1][0] < firstupSpeed[i][k][0]) {
            var up = k
          }
        }
        if (up == 0 || up == undefined) {
          up = firstupSpeed[i].length
        }
        b.push(up)
        up = 0
      }
      var speedUpone = [] //第一次提钻
      var speedUptwo = [] //第二次提钻
      for (let j = 0; j < firstupSpeed.length; j++) {
        speedUpone.push(firstupSpeed[j].slice(0, b[j]))
        speedUptwo.push(firstupSpeed[j].slice(b[j]))
      }
      // 第一次提钻速度
      var time3 = 0
      var total3 = 0
      for (let i = 0; i < speedUpone.length; i++) {
        for (let j = 0; j < speedUpone[i].length; j++) {
          time3 += speedUpone[i][j][1]
          total3 += speedUpone[i][j][1] * speedUpone[i][j][2]
        }
        if (total3 / time3 == "Infinity" || speedUpone[i].length == 0) {
          this.checkedData[i].speed3 = 0
        } else {
          this.checkedData[i].speed3 = Math.abs((total3 / time3).toFixed(2))
        }
        time3 = 0
        total3 = 0
      }
      // 第二次提钻速度
      var time4 = 0
      var total4 = 0
      for (let i = 0; i < speedUptwo.length; i++) {
        for (let j = 0; j < speedUptwo[i].length; j++) {
          time4 += speedUptwo[i][j][1]
          total4 += speedUptwo[i][j][1] * speedUptwo[i][j][2]
        }
        if (total4 / time4 == "Infinity" || speedUptwo[i].length == 0) {
          this.checkedData[i].speed4 = 0
        } else {
          this.checkedData[i].speed4 = Math.abs((total4 / time4).toFixed(2))
        }
        time4 = 0
        total4 = 0
      }
      if (this.checkedData.length != 0) {
        this.checkedData.startDate = moment(this.checkedData[0].beginTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.checkedData.endDate = moment(this.checkedData[this.checkedData.length - 1].endTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.checkedData.milePartNo = this.checkedData[this.checkedData.length - 1].milePartNo
      }
      this.checkedId = []
      for (var i = 0; i < e.length; i++) {
        this.checkedId.push(e[i]._id)
      }
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
<style scoped>
.el-button--primary {
  margin-left: 25px;
  margin-right: 5px;
}

.el-checkbox {
  width: 20%;
}

.more {
  margin-left: 1.75rem;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAbCAYAAAB836/YAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4MUQ5MUJCNkEzRkYxMUVCQTE1NUVDNDc5MDNFOTc4OSIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4MUQ5MUJCN0EzRkYxMUVCQTE1NUVDNDc5MDNFOTc4OSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjgxRDkxQkI0QTNGRjExRUJBMTU1RUM0NzkwM0U5Nzg5IiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjgxRDkxQkI1QTNGRjExRUJBMTU1RUM0NzkwM0U5Nzg5Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+B0oTKQAAAE9JREFUeNpi/P//PwM1ARMDlcGogUPUQE8gfgbFnoQUMxKRDkEGSULZz4FYippeZqSGl1OA+AUQPwXiZGp4eTQdjibs0YQ9mg5HgoEAAQYAMKsdCdjHQ/QAAAAASUVORK5CYII=)
    center center no-repeat;
  background-size: contain;
  float: left;
  border-radius: 0.3125rem;
  -webkit-border-radius: 0.3125rem;
  -moz-border-radius: 0.3125rem;
  -ms-border-radius: 0.3125rem;
  -o-border-radius: 0.3125rem;
  overflow: hidden;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  cursor: pointer;
}

.jbThead {
  font-size: 14px;
  color: #515a6e;
}

.ul-detail .inner {
  padding: 0.625rem 0 0.625rem 0.3rem;
}

.ul-detail .s3 .tit,
.ul-detail .s4 .tit {
  min-width: 10em;
}

.ul-detail .s1 .tit {
  margin-right: 0;
  min-width: 4.8em;
}

.valuepile {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}

.ul-detail li:first-child {
  padding-left: 1.3rem;
}
</style>
