<template>
  <div class="row-history">
    <component :is="report" :selectItem="selectItem" :checkedData="checkedData"></component>
    <a id="simulation" href=""></a>
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
                <el-button type="primary" @click="dialogFormVisible = true">选择参数</el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" multiple filterable reserve-keyword placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="number"></el-input>
                </el-form-item>
                <el-select class="selectLevel" v-model="queryStatus" placeholder="预警状态">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <el-main class="m-table-main">
        <div id="table-content">
          <el-table :data="tableData" :cell-style="backgroundColor" @selection-change="selectionChange" style="width: 100%" ref="table" row-key="id" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" align='center' fixed></el-table-column>
            <el-table-column v-for="(col,index) in cols" :key="index" :prop="col.prop" :label="col.label" :formatter="col.formatter" :min-width='col.minWidth' align='center' show-overflow-tooltip></el-table-column>
            <el-table-column width="80" align='center' label="详情" fixed="right">
              <template slot-scope="scope">
                <a href="javascript:" class="querDetails" @click="handleCommand(scope.$index)">查看详情</a>
              </template>
            </el-table-column>
            <el-table-column width="90" align='center' property="status" label="状态" fixed="right">
              <template slot-scope="scope">
                {{scope.row.status|statusFilter}}
              </template>
            </el-table-column>
            <el-table-column width="80" align="center" label="操作" fixed="right">
              <template slot-scope="scope">
                <a href="javascript:" class="querDetails" @click="alertInfo(scope.$index)">处理</a>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
    </el-container>
    <el-dialog title="选择需要展示的参数" :visible.sync="dialogFormVisible" width="40%" center top="15vh">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedFileds" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="item in fieldNames" :label="item.prop" :key="item.prop">{{ item.label }}</el-checkbox>
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
        <el-button type="primary" @click="confirmShow" size="medium">确定</el-button>
      </div>
    </el-dialog>
    <!-- 弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
      </div>
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <!-- 详细数据 -->
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value">{{selectItem.pileDescribe}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩机编号</div>
                        <div class="value">{{selectItem.deviceKey}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">施工桩长</div>
                        <div class="value">{{selectItem.depth|numfilters}}{{"\xa0"}}m</div>
                      </div>
                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">初搅深度</div>
                        <div class="value">{{selectItem.frDepth|numfilters}}{{"\xa0"}}m</div>
                      </div>
                      <div class="item">
                        <div class="tit">复搅深度</div>
                        <div class="value">{{selectItem.reDepth|numfilters}}{{"\xa0"}}m</div>
                      </div>
                      <div class="item">
                        <div class="tit">空搅深度</div>
                        <div class="value">{{selectItem.emDepth|numfilters}}{{"\xa0"}}m</div>
                      </div>
                      <div class="item">
                        <div class="tit">下钻速度</div>
                        <div class="value">{{selectItem.downSpeed|numfilters}}{{"\xa0"}}cm/min</div>
                      </div>
                      <div class="item">
                        <div class="tit">提钻速度</div>
                        <div class="value">{{selectItem.upSpeed|numfilters}}{{"\xa0"}}cm/min</div>
                      </div>
                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">累计灰量</div>
                        <div class="value">{{selectItem.cumulativeAsh|numfilters}}{{"\xa0"}}kg</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均灰量</div>
                        <div class="value">{{selectItem.averageAsh|numfilters}}{{"\xa0"}}kg/m</div>
                      </div>
                      <div class="item">
                        <div class="tit">成桩时间</div>
                        <div class="value">{{selectItem.pileTime|minfilters}}{{"\xa0"}}min</div>
                      </div>
                      <div class="item">
                        <div class="tit">最大电流</div>
                        <div class="value">{{selectItem.maxCurrent|numfilters(1)}}{{"\xa0"}}A</div>
                      </div>
                      <div class="item">
                        <div class="tit">最大斜度</div>
                        <div class="value">{{selectItem.maxAngle|numfilters}}{{"\xa0"}}%</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <!-- 段数据 -->
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.data" style="width: 100%;margin-top:20px" height="28em">
              <el-table-column property="partId" label="序号" min-width="8.33%" align='center'>
              </el-table-column>
              <el-table-column property="partDeep" label="段深度(m)" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partDeep|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="partAsh" label="段灰量(kg)" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partAsh|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partCurrent" label="段电流(A)" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partCurrent|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="partDownSpeed" label="速度(cm/min)" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|num3filters }}
                </template>
              </el-table-column>
              <el-table-column property="partTime" label="段时长(s)" min-width="8.33%" align='center'>
              </el-table-column>
              <el-table-column property="partDownSpeed" label="状态" min-width="8.33%" align='center'>
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|num5filters }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart03" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 预警操作 -->
    <el-dialog :visible.sync="handleDialog" width="50%">
      <div class="upload-demo">
        <el-upload :file-list="UploadPath" :on-preview="onPreview" accept=".jpg,.jpeg,.png" :multiple="true" :before-upload="beforeImgUpload" :on-remove="handleRemove" :on-success="success" action="/api/file/upload" :headers="headers" list-type="picture-card" :disabled="UploadDisable">
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div class="dialogBot">
        <el-input clear="upInput" v-model="alRemarks" autosize type="textarea" placeholder="请输入内容"></el-input>
        <div class="uploadBtn">
          <el-button :disabled="disabled" v-show="showButton" size="small" type="primary" @click="uploadQD()">{{imgUploadButton}}</el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 上传图片放大 -->
    <el-dialog :visible.sync="ImgdialogVisible" width="50%" center top="15vh">
      <img style="width:100%" :src="ImgSrc" alt="">
    </el-dialog>
    <el-footer class="m-selectAll">
      <div class="left">
        <div class="item">
          <el-checkbox v-model="totalChecked" class="total" @change="totalChange">全选
          </el-checkbox>
        </div>
        <div class="item">
          <div class="export" @click="exportAll">导出</div>
        </div>
      </div>
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,sizes,jumper" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import tableToExcel from '../../utils/report'
import report from '../report/report.vue'
import moment from "moment";
import { historyWarns, editWarn } from "@/api/api"

import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
const allField = ['pileDescribe', 'deviceKey', 'warnreason', 'beginTime', 'endTime', 'depth', 'averageAsh', 'pileTime', 'frDepth', 'reDepth',
  'emDepth', 'pileTopCurrent', 'cumulativeAsh', 'maxCurrent', 'downSpeed', 'upSpeed', 'maxAngle', 'averageCurrent',]
export default {
  components: {
    gTabs, report
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toLastPage', 'toFirstPage', 'pileDescribe'],
  data() {
    return {
      alertHandleData: [],
      fileList: [],
      headers: { token: sessionStorage['token'] },
      UploadPath: [],
      UploadDisable: false,
      alRemarks: '',
      disabled: false,
      showButton: true,
      imgUploadButton: '提交',//确定

      handleDialog: false,
      ImgdialogVisible: false,
      ImgSrc: '',
      queryStatus: '',
      options: [
        {
          value: '',
          label: '全部预警'
        }, {
          value: -2,
          label: '待处理'
        }, {
          value: 2,
          label: '已通过检查'
        }
      ], //预警状态
      checkedData: [],
      report: 'report',
      //excel报表数据
      totalChecked: false,
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',
      checkAll: false,  //默认全选
      checkedFileds: ['pileDescribe', 'deviceKey', 'warnreason', 'beginTime', 'endTime', 'depth', 'averageAsh', 'downSpeed', 'upSpeed', 'pileTopCurrent', 'maxAngle', 'pileTime',], //默认显示选中的数据
      fieldNames: [
        { prop: 'pileDescribe', label: '桩号', minWidth: '160px' },
        { prop: 'deviceKey', label: '桩机号', minWidth: '160px' },
        { prop: 'warnreason', label: '预警原因', minWidth: '160px' },
        { prop: 'beginTime', label: '开始时间', formatter: this.testFormatter, minWidth: '160px' },
        { prop: 'endTime', label: '结束时间', formatter: this.testFormatter, minWidth: '160px' },
        { prop: 'depth', label: '施工桩长(m)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'averageAsh', label: '平均灰量(kg/m)', formatter: this.numFormatter, minWidth: '120px' },
        { prop: 'pileTime', label: '成桩时间(min)', formatter: this.minFormatter, minWidth: '110px' },
        { prop: 'frDepth', label: '初搅深度(m)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'reDepth', label: '复搅深度(m)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'emDepth', label: '空搅深度(m)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'pileTopCurrent', label: '桩端电流(A)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'cumulativeAsh', label: '累计灰量(kg)', formatter: this.numFormatter, minWidth: '110px' },
        { prop: 'maxAngle', label: '最大斜度(%)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'maxCurrent', label: '最大电流(A)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'downSpeed', label: '下钻速度(cm/min)', formatter: this.numFormatter, minWidth: '135px' },
        { prop: 'upSpeed', label: '提钻速度(cm/min)', formatter: this.numFormatter, minWidth: '135px' },
        { prop: 'averageCurrent', label: '平均电流(A)', formatter: this.numFormatter, minWidth: '100px' },
      ],   //对象数组全部的数据
      isIndeterminate: false,   //默认全选
      dialogFormVisible: false,
      cols: [
        { prop: 'pileDescribe', label: '桩号', minWidth: '160px' },
        { prop: 'deviceKey', label: '桩机号', minWidth: '160px' },
        { prop: 'warnreason', label: '预警原因', minWidth: '160px' },
        { prop: 'beginTime', label: '开始时间', formatter: this.testFormatter, minWidth: '160px' },
        { prop: 'endTime', label: '结束时间', formatter: this.testFormatter, minWidth: '160px' },
        { prop: 'depth', label: '施工桩长(m)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'averageAsh', label: '平均灰量(kg/m)', formatter: this.numFormatter, minWidth: '120px' },
        { prop: 'downSpeed', label: '下钻速度(cm/min)', formatter: this.numFormatter, minWidth: '135px' },
        { prop: 'upSpeed', label: '提钻速度(cm/min)', formatter: this.numFormatter, minWidth: '135px' },
        { prop: 'pileTopCurrent', label: '桩端电流(A)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'maxAngle', label: '最大斜度(%)', formatter: this.numFormatter, minWidth: '100px' },
        { prop: 'pileTime', label: '成桩时间(min)', formatter: this.minFormatter, minWidth: '110px' },
      ],
      tempCols: [],  //临时的数据容器
      centerDialogVisible: false,// 弹窗
      data: [],
      selectItem: [],
      tab2: 0,
      tab2List: [{ name: "详细内容", }, { name: "图表曲线", }],
      inquire: [],
      totalNum: 0,
      echart: [],
      echartload: [],
      showbtn: false
    }
  },
  filters: {
    statusFilter(val) {
      if (val == -1) {
        return '已处理，待审核'
      } if (val == 0) {
        return '审核'
      } if (val == 1) {
        return '复核'
      } if (val == 2) {
        return '已通过检查'
      } else {
        return '待处理'
      }
      //  if (val == 1) {
      //   return '复核'
      // } 
    },
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        return val
      } else {
        return parseFloat(val).toFixed(deg)
      }
    },
    minfilters(val) {
      return (parseFloat(val) / 60).toFixed(2)
    },
    num1filters(val) {
      return parseFloat(val).toFixed(2) + '%'
    },
    num3filters(val) {
      return Math.abs(parseFloat(val).toFixed(2))
    },
    num5filters(val) {
      return val < 0 ? '提钻' : '下钻'
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.showbtn = false
        this.tab2 = 0
        this.data = []
        if (this.myChart01 == undefined || this.myChart01._disposed) return
        this.myChart01.dispose()
        this.myChart02.dispose()
        this.myChart03.dispose()
        this.myChart04.dispose()
      }
    },
    handleDialog(val) {
      if (val == false) {
        this.UploadPath = []
        this.fileList = []
        this.alRemarks = ''
      }
    }
  },
  mounted() {
    // this.deviceKey = this.device
    if (this.device == '') {
      this.deviceKey = ''
    } else {
      this.deviceKey = [this.device]
    }
  },
  methods: {
    testFormatter(row, column, cellValue, index) {
      return moment(cellValue * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numFormatter(row, column, cellValue, index) {
      if (isNaN(cellValue)) {
        return 0
      } else {
        return parseFloat(cellValue).toFixed(2)
      }
    },
    num11Formatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(1)
    },
    minFormatter(row, column, cellValue, index) {
      return (parseFloat(cellValue) / 60).toFixed(0)
    },
    exportAll() {
      tableToExcel('FPZstatisticsTableRecord', '粉喷桩桩-导出')
    },
    // 查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.deviceKey.length > 0) {
        var device = this.deviceKey.join()
      } else {
        device = ''
      }
      historyWarns('FPZ', 0, device, this.pageSize, this.number, this.queryStatus, this.startDate, this.endDate,).then((res) => {
        // this.tableData = res.content;
        // this.total = res.totalElements;
        this.inquire = res.content;
        this.totalNum = res.totalElements;
        this.warnReason()
        this.$emit('changeDevicekey', device, this.number, this.inquire, this.queryStatus, this.startDate, this.endDate, this.totalNum)
        loading.close()
      });
      setTimeout(() => {
        loading.close()
      }, 6000)
    },
    resetClick() {
      this.deviceKey = "";
      this.number = '', this.startDate = '', this.endDate = ''
    },
    // 预警处理弹出框
    alertInfo(index) {
      this.handleDialog = true
      this.alertHandleData = this.tableData[index]
      if (this.alertHandleData.filePaths != undefined || this.alertHandleData.remarks != undefined) {
        this.UploadPath = this.alertHandleData.filePaths
        this.alRemarks = this.alertHandleData.remarks[0]
      }
      if (this.alertHandleData.status == 2) {
        this.imgUploadButton = '已闭合'
        this.disabled = true
      } else {
        this.imgUploadButton = '提交'
        this.disabled = false
      }
    },
    // 删除图片
    handleRemove(file, fileList) {
      this.fileList = [];
      if (fileList.length != 0) {
        for (let i = 0; i < fileList.length; i++) {
          let obj = {
            url: fileList[i].url
          }
          this.fileList.push(obj)
        }
      }
    },
    // 上传成功
    success(response) {
      let obj = {
        url: response
      }
      this.fileList.push(obj)
    },
    beforeImgUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isPng = file.type === "image/png";
      const isJpeg = file.type === "image/jpeg";

      const is10M = (file.size / 1024 / 1024) < 10;
      //限制文件上传类型
      if (!isJPG && !isPng && !isJpeg) {
        this.$message.error("上传图片只能是 png,jpg,jpeg 格式!");
        return false
      }
      //限制文件上传大小
      if (!is10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        return false
      }
      return true
    },
    // 上传确定按钮
    uploadQD() {
      let uploadData = { 'a': this.fileList, 'b': [this.alRemarks] }
      this.$confirm('确定提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        editWarn(this.alertHandleData._id, uploadData, 2).then(() => {
          historyWarns('FPZ', this.currentPage - 1, this.device, this.pageSize, this.pileDescribe, this.queryStatus, this.startDate, this.endDate).then((res) => {
            this.inquire = res.content
            this.totalNum = res.totalElements
            this.warnReason()
            this.$emit('changeDevicekey', this.device, this.pileDescribe, this.inquire, '', this.startDate, this.endDate, this.totalNum)
          });
        })
        this.$message({ type: 'success', message: '上传成功!' });
        this.handleDialog = false
      }).catch(() => {
        this.$message({ type: 'info', message: '上传已取消' });
        this.handleDialog = false
      });
    },
    // 点击预览
    onPreview(scope) {
      this.ImgdialogVisible = true
      this.ImgSrc = scope.url
    },
    // 预警原因
    warnReason() {
      for (let i = 0; i < this.inquire.length; i++) {
        this.inquire[i].warnreason = ''
        // if (this.inquire[i].depthError > 0) {
        //   this.inquire[i].warnreason += '桩长不足; 监测值:' + this.inquire[i].depth.toFixed(2) + ',预警门限:' + this.inquire[i].designDepth.toFixed(2) + `\n`
        // }
        // if (this.inquire[i].ashError > 0) {
        //   this.inquire[i].warnreason += '灰量不足; 监测值:' + this.inquire[i].averageAsh.toFixed(2) + ',预警门限:' + this.inquire[i].designAsh.toFixed(2) + `\n`
        // }
        if (Math.abs(this.inquire[i].upSpeed) > 100) {
          this.inquire[i].warnreason += '提速超标:监测值:' + this.inquire[i].upSpeed.toFixed(2) + ',预警门限:' + -100 + `\n`
        }
        if (this.inquire[i].downSpeed > 0) {
          this.inquire[i].warnreason += '钻速超标:监测值:' + this.inquire[i].downSpeed.toFixed(2) + ',预警门限:' + 100 + `\n`
        }
      }
    },

    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index == 0) {
        this.showbtn = false
      }
      if (index === 1) {
        this.showbtn = true
        this.$nextTick(() => {
          this.initEchart01();
          this.initEchart02();
          this.initEchart03();
          this.initEchart04();
          echarts.connect(this.echart)
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
            self.myChart03.resize();
            self.myChart04.resize();
          };
        });
      }
    },
    // 点击详情
    handleCommand(index) {
      this.selectItem = this.tableData[index]
      this.centerDialogVisible = true; // 显示弹出框
      var day = this.selectItem.beginTime
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        this.data.push(moment(day * 1000).format('YYYY-MM-DD HH:mm:ss'))
        day += this.tableData[index].data[i].partTime
      }
    },
    // 选中发生变化
    selectionChange(e) {
      this.checkedData = e.reverse()
      if (this.checkedData.length != 0) {
        this.checkedData.ENDTime = moment(this.checkedData[this.checkedData.length - 1].endTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.checkedData.startTime = moment(this.checkedData[0].beginTime * 1000).format('YYYY-MM-DD HH:mm:ss')
      }

      var date = []
      var speed1 = []
      var upspeed1 = []
      var daochu = 0
      for (let i = 0; i < e.length; i++) {
        date.push(e[i].data)
      }
      // 第一次下钻提钻
      var downspeed = []
      var upspeed = []
      for (let i = 0; i < date.length; i++) {
        for (let j = 0; j < date[i].length; j++) {
          if (date[i][j].partDownSpeed > daochu) {
            downspeed.push([date[i][j].partDeep, date[i][j].partTime, date[i][j].partDownSpeed])
            // daochu = date[i][j].partDeep
          }
          if (date[i][j].partDownSpeed < daochu) {
            upspeed.push([date[i][j].partDeep, date[i][j].partTime, date[i][j].partDownSpeed])
            // daochu = date[i][j].partDeep
          }
        }
        speed1.push(downspeed)
        upspeed1.push(upspeed)
        downspeed = []
        upspeed = []
        daochu = 0
      }
      // 第一次下钻速度
      var time1 = 0
      var total1 = 0
      for (let i = 0; i < speed1.length; i++) {
        for (let j = 0; j < speed1[i].length; j++) {
          time1 += speed1[i][j][1]
          total1 += speed1[i][j][1] * speed1[i][j][2]
        }
        if (total1 / time1 == "Infinity" || speed1[i].length == 0) {
          this.checkedData[i].speed1 = 0
        } else {
          this.checkedData[i].speed1 = Math.abs((total1 / time1).toFixed(2))
        }
        time1 = 0
        total1 = 0
      }
      // 第一次提钻速度
      var time2 = 0
      var total2 = 0
      for (let i = 0; i < upspeed1.length; i++) {
        for (let j = 0; j < upspeed1[i].length; j++) {
          time2 += upspeed1[i][j][1]
          total2 += upspeed1[i][j][1] * upspeed1[i][j][2]
        }
        if (total2 / time2 == "Infinity" || upspeed1[i].length == 0) {
          this.checkedData[i].speed2 = 0
        } else {
          this.checkedData[i].speed2 = Math.abs((total2 / time2).toFixed(2))
        }
        time2 = 0
        total2 = 0
      }
      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
    initEchart01() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep];
      });
      let option = {
        title: {
          text: "深度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度(m)",
            type: "value",
            inverse: true,
            nameLocation: "end",
            max: 30,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart01.setOption(option);
      this.echart.push(this.myChart01)
    },
    initEchart02() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partCurrent.toFixed(2)];
      });
      let option = {
        title: {
          text: "深度、电流曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "电流"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度(m)",
            type: "value",
            inverse: true,  //设置坐标轴翻转
            nameLocation: "end",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
            max: 30
          },
          {
            name: "电流(A)",
            type: "value",
            nameLocation: "start",
            max: 300,
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "电流",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart02.setOption(option);
      this.echart.push(this.myChart02)
    },
    initEchart03() {
      let data = this.data;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partAsh.toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDownSpeed.toFixed(2)];
      });
      let option = {
        title: {
          text: "段灰量、速度变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["段灰量", "速度"],
        },
        grid: {
          top: "23%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "段灰量(kg)",
            type: "value",
            nameLocation: "start",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            },
          },
          {
            name: "速度(cm/min)      ",
            type: "value",
            nameLocation: "start",
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
          },
        ],
        series: [
          {
            name: "段灰量",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "速度",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart03 = echarts.init(this.$refs.myChart03, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart03.setOption(option);
      this.echart.push(this.myChart03)
    },
    initEchart04() {
      var ash = []
      for (var j = 0; j < this.selectItem.data.length; j++) {
        if (ash[Math.ceil(this.selectItem.data[j].partDeep - 1)] == undefined) {
          ash[Math.ceil(this.selectItem.data[j].partDeep - 1)] = 0
        }
        ash[Math.ceil(this.selectItem.data[j].partDeep - 1)] += this.selectItem.data[j].partAsh
      }
      var numbers2 = ash.map(myFunction);
      function myFunction(value, index, array) {
        var data = {
          value: Number(value.toFixed(2)),
          name: index + 1
        }
        return data;
      }
      let option = {
        title: {
          text: "成桩灰量分布",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} : {c}",
        },
        grid: {
          top: "10%",
          bottom: "10%",
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            name: "成桩灰量分布",
            type: "funnel",
            width: "50%",
            left: "center",
            top: "15%",
            bottom: "8%",
            label: {
              show: true,
            },
            labelLine: {
              show: true,
            },
            data: numbers2,
            sort: "none",
            color: '#5470c6'
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart04 = echarts.init(this.$refs.myChart04, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart04.setOption(option);
    },
    //全选按钮
    handleCheckAllChange(val) {
      this.checkedFileds = val ? allField : [];
      this.isIndeterminate = false;
    },
    //单个选中
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fieldNames.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldNames.length;
    },
    confirmShow() {
      this.tempCols = [];
      if (this.checkedFileds.length < 1) {
        this.$message.warning("请至少选择一项数据");
        this.dialogFormVisible = true;
      } else {
        for (var i = 0; i < this.fieldNames.length; i++) {
          for (var j = 0; j < this.checkedFileds.length; j++) {
            if (this.fieldNames[i].prop == this.checkedFileds[j]) {
              this.tempCols.push(this.fieldNames[i]);
            }
          }
        }
        this.cols = this.tempCols;
        this.dialogFormVisible = false;
      }
    },
    // 数据预警颜色
    backgroundColor(row) {
      if (row.column.property == "status") {
        if (row.row.status != 2) {
          return { color: '#ff3333' }
        }
      }
      // if (row.column.property == "depth") {
      //   if (row.row.depthError > 0) {
      //     return { color: '#ff3333' } //红色
      //   }
      // }
      // if (row.column.property == 'averageAsh') {
      //   if (row.row.ashError > 0) {
      //     return { color: '#ff3333' }
      //   }
      // }
      if (row.column.property == "downSpeed") {
        if (row.row.downSpeed > 100) {
          return { color: '#ff3333' }
        }
      }
      if (row.column.property == "upSpeed") {
        if (Math.abs(row.row.upSpeed) > 100) {
          return { color: '#ff3333' }
        }
      }
    }
  }
}
</script>
<style scoped>
.upload-demo {
  margin-bottom: 10px;
}
.dialogBot .uploadBtn {
  text-align: right;
  margin-top: 10px;
}
.m-filter .item.picker {
  width: 19.25rem;
  margin: 0 1rem 0 0.2rem;
}
.selectLevel {
  width: 180px;
  margin-left: 0.3rem;
}
.m-tab-echartTable {
  position: relative;
}
.echartdownLoad {
  position: absolute;
  top: 0;
  left: -2000px;
  width: 800px;
  height: 660px;
}
.ul-detail li {
  width: 33%;
}
.el-checkbox {
  width: 20%;
}
.el-button--primary {
  margin-left: 25px;
  margin-right: 5px;
}

.el-table thead {
  color: #333;
}

/* 修改字体 */
.cell {
  font-size: 14px;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.3rem;
}

.querDetails {
  color: #0b85e3;
}
</style>
