<template>
  <div class="row-history">
    <div class="m-topNav">
      <el-tabs @tab-click="handleClick" v-model="tab">
        <el-tab-pane v-for="item of tabList" :key="item.name" :label="item.label" :name="item.name">
        </el-tab-pane>
      </el-tabs>
    </div>
    <component :is='tab' @changeDevicekey="changeDevicekey" :pileDescribe="pileDescribe" :tableData="tableData" :device="device" :deviceList="deviceList" :pageSize="pageSize" :total="total" :currentPage="currentPage" :handleSizeChange="handleSizeChange" :handleCurrentChange="handleCurrentChange" :toLastPage="toLastPage" :toFirstPage="toFirstPage"></component>
  </div>
</template>
<script>
import moment from "moment";
import { queryGroups, historyWarns, queryDevices } from "@/api/api"
import JBZ from "@/views/alertInfo/JBZ"
import GFZ from "@/views/alertInfo/GFZ"
import GH from "@/views/alertInfo/GH"
import GYXPZ from "@/views/alertInfo/GYXPZ"
import A_RD from "@/views/alertInfo/A_RD"
import FPZ from "@/views/alertInfo/FPZ"
export default {
  name: 'alertInfo',
  components: {
    JBZ, GFZ, GH, GYXPZ, A_RD, FPZ
  },
  data() {
    return {
      tab: "GH",
      tabList: [],
      deviceList: [],
      tableData: [],
      pileDescribe: '', //桩号
      device: '', //设备号
      beginTimes: '',
      endTimes: '',
      total: 0,
      pageSize: 10,
      currentPage: 1,
      status: ''
    }
  },
  mounted() {
    queryGroups().then(res => {
      let tbaListName = []
      let list = [{ label: "粉喷桩", name: "FPZ" }, { label: "水泥土搅拌桩", name: "GFZ" }, { label: "RTK成槽", name: "A_RD" }]
      let ListMap = new Map()
      for (let i = 0; i < res.length; i++) {
        ListMap.set(res[i].name, res[i])
      }
      for (let i = 0; i < list.length; i++) {
        if (ListMap.get(list[i].name) != undefined) {
          tbaListName.push(list[i])
        }
      }
      if (tbaListName.length == 0) {
        this.tabList = res
      } else {
        this.tabList = tbaListName
      }
      this.tab = this.tabList[0].name;
      if (sessionStorage['type'] != undefined) {
        if (sessionStorage['type'] == 'A_RD' || sessionStorage['type'] == 'FPZ' || sessionStorage['type'] == 'GFZ') {
          this.tab = sessionStorage['type']
        } else {
          this.tab = this.tabList[0].name
        }
      } else {
        this.tab = this.tabList[0].name;
      }
      if (sessionStorage['deviceKey'] != undefined) {
        this.device = sessionStorage['deviceKey']
      } else {
        this.device = ''
      }
      queryDevices(9999, 1, this.tab).then((res) => {
        this.deviceList = res.result.records;
        this.historyWarnList()
        sessionStorage.removeItem('deviceKey')
      });
    })
  },
  methods: {
    // 接受子页面传过来的数据
    changeDevicekey(deviceKey, number, inquire, status, startDay, endDay, totalNum) {
      this.tableData = inquire
      this.pileDescribe = number
      this.device = deviceKey
      this.beginTimes = startDay
      this.endTimes = endDay
      this.status = status
      this.total = totalNum
    },
    // 超标原因
    warnReason() {
      if (this.tableData != undefined) {
        if (this.tab == 'GFZ') {
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].warnreason = ''
            if (this.tableData[i].depthError > 0) {
              this.tableData[i].warnreason += '桩长不足:' + (this.tableData[i].depthError * 100).toFixed(2) + '%;'
            }
            if (this.tableData[i].ashError > 0) {
              this.tableData[i].warnreason += '灰量不足:' + (this.tableData[i].ashError * 100).toFixed(2) + '%;'
            }
            if (this.tableData[i].upSpeedError > 0) {
              this.tableData[i].warnreason += '提速超标:' + (this.tableData[i].upSpeedError * 100).toFixed(2) + '%;'
            }
            if (this.tableData[i].downSpeedError > 0) {
              this.tableData[i].warnreason += '钻速超标:' + (this.tableData[i].downSpeedError * 100).toFixed(2) + '%'
            }
            if (this.tableData[i].maxAngle > 0.5) {
              this.tableData[i].warnreason += '倾角超标:' + ((this.tableData[i].maxAngle - 0.5) / 0.5 * 100).toFixed(2) + '%'
            }
          }
        }
        if (this.tab == 'FPZ') {
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].warnreason = ''
            // if (this.tableData[i].depthError > 0) {
            //   this.tableData[i].warnreason += '桩长不足; 监测值:' + this.tableData[i].depth.toFixed(2) + ',预警门限:' + this.tableData[i].designDepth.toFixed(2) + `\n`
            // }
            // if (this.tableData[i].ashError > 0) {
            //   this.tableData[i].warnreason += '灰量不足; 监测值:' + this.tableData[i].averageAsh.toFixed(2) + ',预警门限:' + this.tableData[i].designAsh.toFixed(2) + `\n`
            // }
            if (Math.abs(this.tableData[i].upSpeed) > 100) {
              this.tableData[i].warnreason += '提速超标:监测值:' + this.tableData[i].upSpeed.toFixed(2) + ',预警门限:' + -100 + `\n`
            }
            if (this.tableData[i].downSpeed > 100) {
              this.tableData[i].warnreason += '钻速超标:监测值:' + this.tableData[i].downSpeed.toFixed(2) + ',预警门限:' + 100 + `\n`
            }
          }
        }
        if (this.tab == 'A_RD') {
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].warnreason = '成槽深度不足;监测值:' + this.tableData[i].maxDepth.toFixed(2) + ',预警门限:' + this.tableData[i].designPileLength.toFixed(1)

          }
        }

      }
    },
    deviceNames() {
      let map = new Map()
      for (let i = 0; i < this.deviceList.length; i++) {
        map.set(this.deviceList[i].key, this.deviceList[i].name)
      }
      for (let i = 0; i < this.tableData.length; i++) {
        this.tableData[i].devName = map.get(this.tableData[i].deviceKey)
      }
    },
    // 预警table列表
    historyWarnList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      historyWarns(this.tab, (this.currentPage - 1), this.device, this.pageSize, this.pileDescribe, this.status, this.beginTimes, this.endTimes).then((res) => {
        this.tableData = res.content;
        this.total = res.totalElements;
        this.deviceNames()
        this.warnReason()
        loading.close();
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    // 设备列表
    queryDeviceList() {
      queryDevices(9999, 1, this.tab).then((res) => {
        this.deviceList = res.result.records;
      });
    },
    // tabs标签切换
    handleClick() {
      sessionStorage.removeItem('deviceKey')
      this.device = ''
      this.status = ''
      sessionStorage['type'] = this.tab
      queryDevices(9999, 1, this.tab).then((res) => {
        this.deviceList = res.result.records;
        this.historyWarnList()
      });
    },
    // 点击切换页码
    handleCurrentChange(val) {
      this.currentPage = val
      this.historyWarnList()
      this.queryDeviceList()
    },
    // 每页数据条数切换
    handleSizeChange(val) {
      this.pageSize = val
      this.historyWarnList()
      this.queryDeviceList()
    },
    toLastPage() {
      this.handleCurrentChange(Math.ceil(this.total / this.pageSize))
    },
    toFirstPage() {
      this.handleCurrentChange(1)
    }
  }
}
</script>
<style scoped>
</style>
