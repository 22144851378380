<template>
  <!-- 弹出框 -->
  <div class="row-history">
    <compoent :is="report" :selectItem="selectItem" :partcurrent="partcurrent" :downspeed="downspeed" :upspeed="upspeed" :ashReport="ashReport" :depthReport="depthReport" :pulpReport="pulpReport" :GYXPreport="GYXPreport" :GYXPZreportPile="GYXPZreportPile" :GYXPZbegintime="GYXPZbegintime" :GYXPZendtime="GYXPZendtime"></compoent>
    <a id="simulation" href=""></a>
    <el-header class="m-filter">
      <el-container class="row-con">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
                <el-button type="primary" @click="showData()">选择参数</el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>

                <div class="item">至</div>

                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>

                <el-form-item class="item picker picker-device">
                  <el-select v-model="deviceKey" placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="number"></el-input>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
      </el-container>
    </el-header>
    <!-- <el-button type="primary" @click="showData()">参数选择</el-button> -->
    <el-main class="m-table-main">
      <div id="table-content">
        <el-table :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
          <el-table-column type="selection" align='center' fixed></el-table-column>
          <el-table-column :width="columnWidth(index)" v-for="(col,index) in cols" :key="index" :prop="col.prop" :label="col.label" :formatter="col.formatter" min-width='163px' align='center' show-overflow-tooltip> </el-table-column>
          <el-table-column fixed="right">
            <template slot-scope="scope">
              <div class="m-edit-btns">
                <div class="item">
                  <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-main>
    <el-dialog title="选择需要展示的参数" :visible.sync="dialogFormVisible" width="40%" center top="15vh">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedFileds" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="item in fieldNames" :label="item.prop" :key="item.prop">{{ item.label }}</el-checkbox>
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
        <el-button type="primary" @click="confirmShow" size="medium">确定</el-button>
      </div>
    </el-dialog>

    <!-- 详情弹出框 -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail">
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <div class="right">
          <div v-show="showEchart" class="export_echart" @click="download">图片下载</div>
          <el-dropdown trigger="click" placement="top-start" @command="downLoad">
            <span class="more ">报表下载
            </span>
            <el-dropdown-menu slot="dropdown" class="g-dropdown">

              <el-dropdown-item :command="0">
                <span class="icon icon01"></span>
                <span class="name">打印版</span>
              </el-dropdown-item>
              <el-dropdown-item :command="1">
                <span class="icon icon02"></span>
                <span class="name">综合版</span>
              </el-dropdown-item>
              <el-dropdown-item :command="2">
                <span class="icon icon03"></span>
                <span class="name">详细版</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

      </div>

      <!-- 详细数据 -->
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value">{{selectItem.pileDescribe}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩机编号</div>
                        <div class="value">{{selectItem.deviceKey}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">成桩时间</div>
                        <div class="value">{{selectItem.pileTime|minfilters}}(min)</div>
                      </div>
                      <div class="item">
                        <div class="tit">有效桩长</div>
                        <div class="value">{{selectItem.depth|numfilters}}(m)</div>
                      </div>

                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">累计灰量(kg)</div>
                        <div class="value">{{selectItem.cumulativeAsh}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均灰量(kg/m)</div>
                        <div class="value">{{selectItem.averageAsh|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均浆量(L/m)</div>
                        <div class="value">{{selectItem.averagePulp|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计浆量(L)</div>
                        <div class="value">{{selectItem.cumulativePulp}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">施工桩长(m)</div>
                        <div class="value">{{selectItem.depth}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">空桩桩长(m)</div>
                        <div class="value">{{selectItem.emptyDepth|numfilters}}</div>
                      </div>

                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">水灰比</div>
                        <div class="value">{{selectItem.waterCementRatio|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">最大斜度</div>
                        <div class="value">{{selectItem.maxAngle|num1filters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计气量(m³)</div>
                        <div class="value">{{selectItem.cumulativeAir}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计水量(L)</div>
                        <div class="value">{{selectItem.cumulativeWater}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均提钻速度(cm/min)</div>
                        <div class="value">{{selectItem.upSpeed|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">平均提钻转速(r/min)</div>
                        <div class="value">{{selectItem.upRotateSpeed|numfilters}}</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.data" style="width: 100%;margin-top:20px" height="28em" show-overflow-tooltip>
              <el-table-column type="index" label="序号" min-width="5%" align="center" width="71px">
              </el-table-column>
              <el-table-column property="partDeep" :label="'段深度\nm'" min-width="6.95%" align="center">
              </el-table-column>
              <el-table-column property="partPulp" :label="'段浆量\nL'" min-width="6.95%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPulp|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partPulpPressure" :label="'段浆压\nMPa'" min-width="6.9%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPulpPressure|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partWater" :label="'段水量\nL'" min-width="6.8%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partWater|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partAir" :label="'段气量\nL'" min-width="6.8%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partAir|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partReturnPulp" :label="'段扭力\nMPa'" min-width="7.5%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partReturnPulp|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partReturnPulpPressure" :label="'段地压\nMPa'" min-width="7.5%" align="center">
              </el-table-column>
              <el-table-column property="partWaterPressure" :label="'段水压\nMPa'" min-width="6.9%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partWaterPressure|numfilters }}
                </template>
              </el-table-column>
              <el-table-column property="partAirPressure" :label="'段气压\nMPa'" min-width="6.9%" align="center">
              </el-table-column>
              <el-table-column property="partDensity" :label="'段密度\ng/cm³'" min-width="7%" align="center">
              </el-table-column>
              <el-table-column property="partDownSpeed" :label="'速度\ncm/min'	" min-width="7.6%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|num4filters }}
                </template>
              </el-table-column>
              <el-table-column property="partRotateSpeed" :label="'转速\nr/min'" min-width="7%" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partRotateSpeed|num4filters }}
                </template>
              </el-table-column>
              <el-table-column property="partTime" :label="'段时长\ns'" min-width="7%" align="center">
              </el-table-column>
              <el-table-column property="partDownSpeed" label="状态" min-width="5%" align="center">
                <template slot-scope="scope">
                  {{scope.row.partDownSpeed|num5filters}}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col col_3">
                <div class="inner">
                  <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="outEcharts">
            <div ref="myChart06" :style="{ width: '100%', height: '300px' }"></div>
            <div ref="myChart07" :style="{ width: '100%', height: '300px' }"></div>
          </div>
        </div>
        <div v-show="tab2===2">
          <el-row class="play_back">
            <el-col class="play_col">
              <div class="inner inner1 backEchart">
                <!-- <h2>状态信息</h2> -->
                <div class="m-statusinfo">
                  <div class="top">
                    <h3>{{ selectItem.deviceTypeName}}</h3>
                    <div class="info">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value">
                          {{selectItem.pileDescribe }}
                        </div>
                      </div>
                      <div class="item">
                        <div class="tit">设备编号</div>
                        <div class="value">{{selectItem.deviceKey}}</div>
                      </div>
                    </div>
                    <div class="status">
                      <ul class="ul-status">
                        <li :class="recordStatusClass">
                          <div class="btn">{{pileDownStatus}}</div>
                        </li>
                        <li :class="pipeStatusClass">
                          <div class="btn">{{ pileStatusStr }}</div>
                        </li>
                        <li :class="nozzleStatusClass">
                          <div class="btn">{{pulpStatus}}</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="bottom">
                    <div class="item">
                      <h3>开始时间</h3>
                      <div class="value">{{selectItem.beginTime|datafilters}}</div>
                    </div>
                    <div class="item">
                      <h3>水灰比</h3>
                      <div class="value">{{selectItem.waterCementRatio|numfilters}}</div>
                    </div>
                    <div class="item">
                      <h3>密度</h3>
                      <div class="value">{{dataNum.partDensity|numfilters(3)}} g/cm³</div>
                    </div>
                    <div class="item">
                      <h3>累计水量</h3>
                      <div class="value">{{totalWater|numfilters(2)}}</div>
                    </div>
                    <!-- <div class="item">
                      <h3>记录模式</h3>
                      <div class="value">{{selectItem.recordMode|recordFilter}}</div>
                    </div>
                    <div class="item">
                      <h3>记录间隔</h3>
                      <div class="value">{{selectItem|intervalFilter}}</div>
                    </div> -->
                  </div>
                </div>
              </div>
            </el-col>
            <el-col class="play_col">
              <div class="inner backEchart">
                <!-- <h2>深度、流量时间曲线</h2> -->
                <div ref="myChart05" :style="{ width: '100%', height: '100%' }"></div>
              </div>
            </el-col>
          </el-row>
          <el-row class="play_back">
            <el-col class="play_col">
              <div class="mapDiv" id="mapDiv">
              </div>
            </el-col>
            <el-col class="play_col">
              <div class="inner">
                <div class="m-datab1" style="height:27rem">
                  <div class="top">
                    <div class="left">
                      <div class="icon" ref="angle" style="">
                      </div>
                      <div class="t1">
                        <p>X<b>{{dataNum.xAngle|numfilters(1)}}</b></p>
                        <p>Y<b>{{dataNum.yAngle|numfilters(1)}}</b></p>
                      </div>
                    </div>
                    <div class="oh">
                      <div class="item">
                        <p class="item_center">持续时长(min)</p>
                        <b class="item_center">{{LastTime|minfilters}}</b>
                      </div>
                      <div class="item">
                        <p class="item_center">累计浆量(L)</p>
                        <b class="item_center">{{totalPartPulp|numfilters}}</b>
                      </div>
                      <div class="item">
                        <p class="item_center">累计灰量(kg)</p>
                        <b class="item_center">{{totalPartAsh|numfilters}}</b>
                      </div>
                      <div class="item">
                        <p class="item_center">段浆量(L)</p>
                        <b class="item_center">{{dataNum.partPulp|numfilters}}</b>
                      </div>
                      <div class="item">
                        <p class="item_center">段灰量(kg)</p>
                        <b class="item_center">{{dataNum.partAsh|numfilters}}</b>
                      </div>
                    </div>
                  </div>
                  <div class="picbox">
                    <img src="../../assets/images/c-i3.png" class="bg" alt="">
                    <div class="databar">
                      <div class="data" :style="{bottom:ztTop}">
                        <img src="../../assets/images/c-i5.png" alt="" :class="image">
                        <img src="../../assets/images/c-i2.png" alt="" class="zt">
                        <div class="info">
                          <p><b>{{ pileStatusStr }}</b></p>
                          <p>深度<em>{{dataNum.partDeep|numfilters}}(m)</em></p>
                          <p>速度<em>{{dataNum.partDownSpeed|numfilters}}(cm/min)</em></p>
                          <p>流量<em>{{dataNum.partPulp/dataNum.partTime*60|numfilters}}(L/min)</em></p>
                          <img src="../../assets/images/c-i4.png" alt="" class="line">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="backplayBottom">
            <el-progress class="progress_bar" :show-text="false" :stroke-width="15" :percentage="percentage" :color="color"> </el-progress>
            <i class="iconBack el-icon-refresh-right" @click="stop"></i>
            <i class="iconBack" :class="icon" @click="backPlay"></i>
            <el-select class="selectspeed" size="small" @change="changeSpeeed" v-model="value" placeholder="正常">
              <el-option class="selectspeedOpt" v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <div class="left">
        <div class="item">
          <el-checkbox v-model="totalChecked" class="total" @change="totalChange">全选
          </el-checkbox>
        </div>
        <div class="item">
          <a href="javascript:;" class="export" @click="reportDC">导出</a>
        </div>
      </div>
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot, prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,sizes, jumper" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import report from '../report/report.vue'
import { http } from '../../main'
import { historyList } from "@/api/api"
import moment from "moment";
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
const allField = ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'depth', 'maxPressure', 'waterCementRatio', 'cumulativePulp', 'averagePulp', 'cumulativeAsh', 'averageAsh',
  'cumulativeReturnPulp', 'averageReturnPulp', 'cumulativeWater', 'averageWater', 'cumulativeAir', 'averageAir', 'maxDownSpeed', 'downSpeed', 'maxUpSpeed',
  'upSpeed', 'maxDownRotateSpeed', 'downRotateSpeed', 'maxUpRotateSpeed', 'upRotateSpeed', 'maxAngle', 'emptyDepth', 'pileTime', 'pulpTime']; //所有的数据

export default {
  components: {
    gTabs, report
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'handleCurrentChange', 'toFirstPage', 'toLastPage', 'pileDescribe'],
  data() {
    return {
      depthReport: [],
      pulpReport: {},
      ashReport: {},
      upspeed: {},
      downspeed: {},
      partcurrent: {},
      report: 'report',
      startDate: '',
      endDate: '',
      number: "",
      deviceKey: '',
      params: "",
      paramsList: [
        {
          label: "参数1",
          value: "参数1",
        },
        {
          label: "参数2",
          value: "参数2",
        },
      ],
      totalChecked: false,
      checkAll: false,  //默认全选
      checkedFileds: ['pileDescribe', 'deviceKey', 'beginTime', 'endTime', 'depth', 'maxPressure', 'cumulativePulp', 'cumulativeAsh', 'pileTime', 'pulpTime'], //默认选中的数据
      fieldNames: [{
        prop: 'pileDescribe',
        label: '桩号'
      },
      {
        prop: 'deviceKey',
        label: '桩机号'
      },
      {
        prop: 'beginTime',
        label: '开始时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'endTime',
        label: '结束时间',
        formatter: this.testFormatter,
      },
      {
        prop: 'depth',
        label: '有效桩长(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxPressure',
        label: '喷射压力(mpa)',
        formatter: this.numFormatter
      },
      {
        prop: 'waterCementRatio',
        label: '水灰比',
        formatter: this.numFormatter
      },
      {
        prop: 'cumulativePulp',
        label: '累计浆量(L)',
        formatter: this.numFormatter
      },
      {
        prop: 'averagePulp',
        label: '平均浆量(L/m)',
        formatter: this.numFormatter
      },
      {
        prop: 'cumulativeAsh',
        label: '累计灰量(kg)',
        formatter: this.numFormatter
      },
      {
        prop: 'averageAsh',
        label: '平均灰量(kg/m)',
        formatter: this.numFormatter
      },
      {
        prop: 'cumulativeReturnPulp',
        label: '累计返浆量(L)',
        formatter: this.numFormatter
      },
      {
        prop: 'averageReturnPulp',
        label: '平均返浆量(L)',
        formatter: this.numFormatter
      },
      {
        prop: 'cumulativeWater',
        label: '累计水量(L)',
        formatter: this.numFormatter
      },
      {
        prop: 'averageWater',
        label: '平均水量(L)',
        formatter: this.numFormatter
      },
      {
        prop: 'cumulativeAir',
        label: '累计气量(m³)',
        formatter: this.numFormatter
      },
      {
        prop: 'averageAir',
        label: '平均气量(m³)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxDownSpeed',
        label: '最大钻速(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'downSpeed',
        label: '平均下钻速度(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxUpSpeed',
        label: '最大提速(cm/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'upSpeed',
        label: '平均提钻速度(cm/min)',
        formatter: this.numFormatter
      },

      {
        prop: 'maxDownRotateSpeed',
        label: '最大下转速度(r/min)	',
        formatter: this.numFormatter
      },
      {
        prop: 'downRotateSpeed',
        label: '平均下转速度(r/min)	',
        formatter: this.numFormatter
      },
      {
        prop: 'maxUpRotateSpeed',
        label: '最大提转速度(r/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'upRotateSpeed',
        label: '平均提转速度(r/min)',
        formatter: this.numFormatter
      },
      {
        prop: 'maxAngle',
        label: '最大斜度',
        formatter: this.num1Formatter
      },
      {
        prop: 'emptyDepth',
        label: '空桩桩长(m)',
        formatter: this.numFormatter
      },
      {
        prop: 'pileTime',
        label: '成桩时间(min)',
        formatter: this.minFormatter
      },
      {
        prop: 'pulpTime',
        label: '喷浆时间(min)',
        formatter: this.minFormatter
      }
      ],   //对象数组全部的数据
      isIndeterminate: false,   //默认全选
      dialogFormVisible: false,

      cols: [
        {
          prop: 'pileDescribe',
          label: '桩号'
        },
        {
          prop: 'deviceKey',
          label: '桩机号'
        },

        {
          prop: 'beginTime',
          label: '开始时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'endTime',
          label: '结束时间',
          formatter: this.testFormatter,
        },
        {
          prop: 'depth',
          label: '有效桩长(m)',
          formatter: this.numFormatter
        },
        {
          prop: 'maxPressure',
          label: '喷射压力(mpa)',
          formatter: this.numFormatter
        },
        {
          prop: 'cumulativePulp',
          label: '累计浆量(L)'
        },
        {
          prop: 'cumulativeAsh',
          label: '累计灰量(kg)',
          formatter: this.numFormatter
        },
        {
          prop: 'pileTime',
          label: '成桩时间(min)',
          formatter: this.minFormatter
        },
        {
          prop: 'pulpTime',
          label: '喷浆时间(min)',
          formatter: this.minFormatter
        }

      ],
      tempCols: [],  //临时的数据容器
      centerDialogVisible: false,// 弹窗
      // 点击详情
      echartsdata: [],
      print: [],
      selectItem: [],
      tab2: 0,
      tab2List: [
        {
          name: "详细内容",
        },
        {
          name: "图表曲线",
        },
        {
          name: "历史回放"
        }
      ],
      selectTable: "记录报表下载",
      selectTableList: [
        {
          label: "记录报表下载",
          value: "0",
        },
        {
          label: "记录报表下载1",
          value: "1",
        },
      ],
      inquire: '',
      totalNum: '',
      echartConnect: [],
      echartConnect2: [],
      showEchart: false,
      // 回放数据
      options: [{
        value: 'ordinary',
        label: '正常'
      }, {
        value: 'fast',
        label: '快速'
      }, {
        value: 'topspeed',
        label: '极速'
      }],
      value: 'ordinary',
      color: '#000',
      percentage: 0,
      ztTop: 100,//钻头位置
      image: 'stop',
      pileStatusStr: '停钻',
      pileDownStatus: '结束',
      pulpStatus: '止喷',
      recordStatusClass: '',
      pipeStatusClass: '',
      nozzleStatusClass: '',
      partData: [],
      s1: 0,
      s2: 0,
      y: 0,
      playbackTimeDepth: [],
      playbackTimeFlow: [],
      dataNum: [],
      PlaybackTime: '',
      playerInter: null,
      playerInter2: null,
      LastTime: 0,
      totalPartPulp: 0,
      totalPartAsh: 0,
      pileDeep: 0,
      totalPartTime: 0,
      icon: 'el-icon-video-pause',
      totalWater: 0,
      map: null,
      GYXPreport: [],
      GYXPZreportPile: '',
      GYXPZbegintime: '',
      GYXPZendtime: ''
      // 回放数据
    }
  },
  filters: {
    recordFilter(val) {
      switch (val) {
        case 1:
          return '深度模式'
        case 2:
          return '时间模式'
        case 0:
          return '-'
        default:
          return val
      }
    },
    intervalFilter(val) {
      switch (val.recordMode) {
        case 1:
          return val.recodeInterval + 'cm'
        case 2:
          return val.recodeInterval + 's'
        case 0:
          return '-'
        default:
          return val
      }
    },
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val) {
      return parseFloat(val).toFixed(2)
    },
    minfilters(val) {
      return (parseFloat(val) / 60).toFixed(0)
    },
    num1filters(val) {
      return parseFloat(val).toFixed(1) + '%'
    },
    num4filters(val) {
      return Math.abs(parseFloat(val).toFixed(2))
    },
    num5filters(val) {
      if (val == 0) {
        val = '停钻'
      }
      if (val < 0) {
        val = '提钻'
      }
      if (val > 0) {
        val = '下钻'
      }
      return val
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      if (val == false) {
        this.showEchart = false
        this.tab2 = 0
        this.clearBackData()
        this.upspeed = []
        this.downspeed = []
        this.ashReport = []
        this.depthReport = []
        this.partcurrent = []
        if (this.myChart01 == undefined) return
        this.myChart01.dispose()
        this.myChart02.dispose()
        this.myChart04.dispose()
        this.myChart06.dispose()
        this.myChart07.dispose()

      }
    }
  },
  mounted() {
    // console.log('GYXPZ');
    this.deviceKey = this.device
  },
  methods: {
    // 图片下载
    download() {
      let a = document.createElement('a');
      a.href = this.myChart06.getConnectedDataURL({
        pixelRatio: 2,
        backgroundColor: '#fff'
      })
      a.download = "图片下载.png";
      a.click();
    },
    // 清除回放数据
    clearBackData() {
      this.totalWater = 0
      this.value = 'ordinary'
      this.percentage = 0
      this.ztTop = 100//钻头位置
      this.image = 'stop'
      this.pileStatusStr = '停钻'
      this.pileDownStatus = '结束'
      this.pulpStatus = '止喷'
      this.recordStatusClass = ''
      this.pipeStatusClass = ''
      this.nozzleStatusClass = ''
      this.partData = []
      this.s1 = 0
      this.s2 = 0
      this.y = 0
      this.playbackTimeDepth = []
      this.playbackTimeFlow = []
      this.dataNum = []
      this.PlaybackTime = ''
      this.LastTime = 0
      this.totalPartPulp = 0
      this.totalPartAsh = 0
      this.pileDeep = 0
      this.totalPartTime = 0
      clearInterval(this.playerInter)
      clearInterval(this.playerInter2)
      if (this.myChart05 === undefined || this.myChart05._disposed) return
      this.myChart05.dispose()
    },
    columnWidth(index) {
      if (index == 0 || index == 4 || index == 5 || index == 6 || index == 7) {
        return 140
      }
      if (index == 1 || index == 2 || index == 3) {
        return 150
      }
    },
    downLoad(command) {
      switch (command) {
        case 0: {
          this.tableToExcel('gyxpzStatisticsTablePrint', moment(this.selectItem.beginTime * 1000).format('YYYY-MM-DD') + '-高压旋喷桩-打印版')
          // window.open(http + "/downloadExcel2Print?id=" + this.selectItem._id)
          break
        }
        case 1: {
          this.tableToExcel('gyxpzStatisticsTable', moment(this.selectItem.beginTime * 1000).format('YYYY-MM-DD') + '-高压旋喷桩-综合版')
          // window.open(http + "/downloadExcel?id=" + this.selectItem._id)
          break
        }
        case 2: {
          this.tableToExcel('gyxpzStatisticsTabledetailed', moment(this.selectItem.beginTime * 1000).format('YYYY-MM-DD') + '-高压旋喷桩-详细版')
          // window.open(http + "/downLoadDetailExcel?id=" + this.selectItem._id)
          break
        }
      }
    },
    // 历史导出
    reportDC() {
      this.tableToExcel('GYXPZstatisticsTableRecord', '高压旋喷桩-现场记录表')
    },
    //表格导出的封装
    tableToExcel(tableid, sheetName) {
      let uri = 'data:application/vnd.ms-excel;base64,';
      let template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"' +
        'xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet>'
        + '<x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets>'
        + '</x:ExcelWorkbook></xml><![endif]-->' +
        ' <style type="text/css">' +
        'table td {' +
        'height: 25px;' +
        'font-family: 宋体;' +
        'font-size: 13px;' +
        'text-align: left;' +
        'border: 0.5px solid #000000;' +
        ' }' +
        'table .tHead {' +
        'font-size: 20px;' +
        'text-align:center;' +
        'font-weight: bold;' +
        'height: 40px;' +
        ' }' +
        'table tfoot tr{' +
        'height: 25px;' +
        ' }' +
        'table tbody td{' +
        'text-align: center;' +
        'height: 20px;' +
        ' }' +
        '</style>' +
        '</head><body ><table class="excelTable">{table}</table></body></html>';
      if (!tableid.nodeType) tableid = document.getElementById(tableid);
      let ctx;
      if (tableid) {
        ctx = { worksheet: sheetName || 'Worksheet', table: tableid.innerHTML };
        document.getElementById('simulation').href = uri + this.base64(this.format(template, ctx));
        document.getElementById('simulation').download = sheetName + ".xls";//自定义文件名
        document.getElementById('simulation').click();
      }
    },
    base64(s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    },
    format(s, c) {
      return s.replace(/{(\w+)}/g,
        function (m, p) {
          return c[p];
        });
    },
    // 历史查询按钮
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      historyList('GYXPZ', 0, this.deviceKey, this.pageSize, this.number, this.startDate, this.endDate).then((res) => {
        this.inquire = res.content;
        this.totalNum = res.totalElements;
        this.$emit('changeDevicekey', this.deviceKey, this.number, this.startDate, this.endDate, this.inquire, this.totalNum)
        loading.close();
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    resetClick() {
      this.deviceKey = "";
      this.number = '', this.startDate = '', this.endDate = ''
    },

    testFormatter(row, column, cellValue, index) {
      return moment(cellValue * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2)
    },
    minFormatter(row, column, cellValue, index) {
      return (parseFloat(cellValue) / 60).toFixed(0)
    },
    // minPulpFormatter(row, column, cellValue, index) {
    //   return (parseFloat(cellValue) / 70).toFixed(0)
    // },
    num1Formatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2) + '%'
    },

    //全选按钮
    handleCheckAllChange(val) {
      this.checkedFileds = val ? allField : [];
      this.isIndeterminate = false;
    },
    //单个选中
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fieldNames.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldNames.length;
    },
    // 选择参数button按钮
    showData(row) {
      this.dialogFormVisible = true;
    },
    // 选择参数
    confirmShow() {
      this.tempCols = [];
      if (this.checkedFileds.length < 1) {
        this.$message.warning("请至少选择一项数据");
        this.dialogFormVisible = true;
      } else {
        for (var i = 0; i < this.fieldNames.length; i++) {
          for (var j = 0; j < this.checkedFileds.length; j++) {
            if (this.fieldNames[i].prop == this.checkedFileds[j]) {
              this.tempCols.push(this.fieldNames[i]);
            }
          }
        }
        this.cols = this.tempCols;
        this.dialogFormVisible = false;
      }
    },

    // 图表
    initEchart01() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], Number((item.partPulp / item.partTime * 60).toFixed(2))];
      });
      let option = {
        title: {
          text: "成桩过程曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "流量"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度          ",
            inverse: true,
            nameLocation: "end",
            type: "value",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "          电流",
            inverse: false,
            nameLocation: "start",
            type: "value",
            max: 100,
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "流量",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart01.setOption(option);
      this.echartConnect.push(this.myChart01)
    },
    initEchart02() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp / item.partTime * 60).toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partPulpPressure.toFixed(2)];
      });
      let option = {
        title: {
          text: "压力、流量变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["压力", "流量"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "压力    ",
            inverse: false,
            nameLocation: "start",
            type: "value",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "      流量",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            max: 50,
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "流量",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "压力",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart02.setOption(option);
      this.echartConnect.push(this.myChart02)
    },
    initEchart04() {
      var ash = []
      for (var k = 0; k < this.selectItem.data.length; k++) {
        if (ash[Math.ceil(this.selectItem.data[k].partDeep)] == undefined) {
          ash[Math.ceil(this.selectItem.data[k].partDeep)] = 0
        }
        ash[Math.ceil(this.selectItem.data[k].partDeep)] += this.selectItem.data[k].partAsh
      }
      if (ash[0] == undefined) {
        ash = ash.slice(1)
      }
      var numbers2 = ash.map(myFunction);

      function myFunction(value, index, array) {
        var data = {
          value: Number(value.toFixed(2)),
          name: index + 1
        }
        return data;
      }
      let option = {
        title: {
          text: "成桩灰量分布",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} : {c}",
        },
        grid: {
          top: "10%",
          bottom: "10%",
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            name: "成桩灰量分布",
            type: "funnel",
            width: "50%",
            left: "center",
            top: "15%",
            bottom: "8%",
            label: {
              show: true,
            },
            labelLine: {
              show: true,
            },
            data: numbers2,
            sort: "none",
            color: '#5470c6'
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart04 = echarts.init(this.$refs.myChart04, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart04.setOption(option);
    },
    initEchart05() {
      let option = {
        title: {
          text: "深度、流量曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "流量"],
        },
        grid: {
          top: "15%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度(m)  ",
            type: "value",
            inverse: true,
            nameLocation: "end",
            axisLine: { //设置轴线
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: " 流量(L/min)",
            nameLocation: "start", // 设置坐标轴名称显示位置
            // inverse: true,
            type: "value",
            splitLine: { //网格不显示
              show: false
            },
            nameTextStyle: {
              padding: [10, 20, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: this.playbackTimeDepth,
          },
          {
            name: "流量",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: this.playbackTimeFlow,
          },
        ],
      };

      // 绘制图表
      this.myChart05.setOption(option);
    },
    initEchart06() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], Number((item.partPulp / item.partTime * 60).toFixed(2))];
      });
      let option = {
        title: {
          text: "成桩过程曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "流量"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度          ",
            inverse: true,
            nameLocation: "end",
            type: "value",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "          电流",
            inverse: false,
            nameLocation: "start",
            type: "value",
            max: 100,
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "流量",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart06 = echarts.init(this.$refs.myChart06, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart06.setOption(option);
      this.echartConnect2.push(this.myChart06)
    },
    initEchart07() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp / item.partTime * 60).toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partPulpPressure.toFixed(2)];
      });
      let option = {
        title: {
          text: "压力、流量变化曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["压力", "流量"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "压力    ",
            inverse: false,
            nameLocation: "start",
            type: "value",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "      流量",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            max: 50,
            splitLine: {
              show: false
            },
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            }
          },
        ],
        series: [
          {
            name: "流量",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "压力",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart07 = echarts.init(this.$refs.myChart07, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart07.setOption(option);
      this.echartConnect2.push(this.myChart07)
    },
    // 倾角
    setAngle(xAngle, yAngle) {
      let angleOption = {
        tooltip: {
          show: false,
          trigger: "axis",
          formatter: 'x: ' + xAngle + '<br />y :' + yAngle
        },
        grid: {
          left: "0",
          top: "0",
          right: "0",
          bottom: "0"
        },
        xAxis: {
          type: "value",
          min: "-20",
          max: "20",
          axisLabel: {
            show: false
          }
        },
        yAxis: {
          type: "value",
          min: "-20",
          max: "20",
          axisLabel: {
            show: false
          }
        },
        series: [{
          symbolSize: 10,
          data: [
            [xAngle, yAngle]
          ],
          type: 'scatter'
        }]
      };
      if (this.angle == undefined) {
        this.angle = echarts.init(this.$refs.angle);
      }
      this.angle.setOption(angleOption);
    },
    // 详情切换按钮
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index === 0) {
        this.showEchart = false
        this.clearBackData()
      }
      if (index === 1) {
        this.showEchart = true
        this.$nextTick(() => {
          this.initEchart06();
          this.initEchart07();
          echarts.connect(this.echartConnect2)
          this.initEchart01();
          this.initEchart02();
          this.initEchart04();
          echarts.connect(this.echartConnect)
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
            self.myChart04.resize();
          };
        });
        this.clearBackData()
      }
      if (index === 2) {
        this.showEchart = false
        this.$nextTick(() => {
          this.loadmap()
          // 基于准备好的dom，初始化echarts实例
          this.myChart05 = echarts.init(this.$refs.myChart05, null, { renderer: 'svg' });
          this.initEchart05();
          window.onresize = function () {
            self.myChart05.resize();
            self.map.checkResize();
          };
        })
        this.partData = this.selectItem.data
        this.center = [this.selectItem.longitude, this.selectItem.latitude]
        for (let i = 0; i < this.partData.length; i++) {
          this.totalPartTime += this.partData[i].partTime
        }
        this.PlaybackTime = this.selectItem.beginTime
        this.playbackTimeDepth.push([moment(this.PlaybackTime * 1000).format('YYYY-MM-DD HH:mm:ss'), 0])
        this.playbackTimeFlow.push([moment(this.PlaybackTime * 1000).format('YYYY-MM-DD HH:mm:ss'), 0])
        this.playerInter = setInterval(() => {
          this.backTimeInterval1()
        }, 3000)
        this.playerInter2 = setInterval(() => {
          this.backTimeInterval2()
        }, 3000)
      }
    },
    // 回放定时1
    backTimeInterval1() {
      this.percentage = Number((this.LastTime / this.totalPartTime * 100).toFixed(1))
      this.pileDownStatus = '开始'
      this.recordStatusClass = 'on'
      this.myFun(this.partData[this.s1++].partDeep)
      if (this.s1 == this.partData.length) {
        this.percentage = 100
        this.pileDownStatus = '结束'
        this.icon = 'el-icon-video-play'
        this.recordStatusClass = ''
        clearInterval(this.playerInter)
      }
    },
    // 回放定时2
    backTimeInterval2() {
      this.dataNum = this.partData[this.s2++]
      this.setAngle(this.dataNum.xAngle, this.dataNum.yAngle)
      this.PlaybackTime += this.dataNum.partTime
      this.playbackTimeDepth.push([moment(this.PlaybackTime * 1000).format('YYYY-MM-DD HH:mm:ss'), this.dataNum.partDeep])
      this.playbackTimeFlow.push([moment(this.PlaybackTime * 1000).format('YYYY-MM-DD HH:mm:ss'), (this.dataNum.partPulp / this.dataNum.partTime * 60).toFixed(2)])
      this.LastTime += this.dataNum.partTime
      this.totalPartPulp += this.dataNum.partPulp
      this.totalPartAsh += this.dataNum.partAsh
      this.totalWater += this.dataNum.partWater
      if (this.pileDeep < this.dataNum.partDeep) {
        this.pileStatusStr = '下钻'
        this.image = 'down'
        this.pulpStatus = '喷浆'
      } else {
        this.pileStatusStr = '提钻'
        this.image = 'up'
        this.pulpStatus = '止喷'
      }
      this.pileDeep = this.dataNum.partDeep
      this.pipeStatusClass = 'on'
      this.nozzleStatusClass = 'on'
      this.initEchart05()
      if (this.s2 == this.partData.length) {
        this.pipeStatusClass = ''
        this.nozzleStatusClass = ''
        this.pileStatusStr = '停钻'
        this.image = 'stop'
        this.pulpStatus = '止喷'
        clearInterval(this.playerInter2)
      }
    },
    // 切换定时速度
    changeSpeeed() {
      if (this.pileDownStatus == '结束') {
        return
      } else {
        if (this.value == 'fast') {
          clearInterval(this.playerInter)
          clearInterval(this.playerInter2)
          this.playerInter = setInterval(() => {
            this.backTimeInterval1()
          }, 2000)
          this.playerInter2 = setInterval(() => {
            this.backTimeInterval2()
          }, 2000)
        } else if (this.value == 'topspeed') {
          clearInterval(this.playerInter)
          clearInterval(this.playerInter2)
          this.playerInter = setInterval(() => {
            this.backTimeInterval1()
          }, 1000)
          this.playerInter2 = setInterval(() => {
            this.backTimeInterval2()
          }, 1000)
        } else if (this.value == 'ordinary') {
          clearInterval(this.playerInter)
          clearInterval(this.playerInter2)
          this.playerInter = setInterval(() => {
            this.backTimeInterval1()
          }, 3000)
          this.playerInter2 = setInterval(() => {
            this.backTimeInterval2()
          }, 3000)
        }
      }
    },
    // 开始暂停按钮
    backPlay() {
      if (this.icon == 'el-icon-video-pause') {
        clearInterval(this.playerInter)
        clearInterval(this.playerInter2)
        this.icon = 'el-icon-video-play'
      } else if (this.icon == 'el-icon-video-play') {
        if (this.pileDownStatus == '结束') {
          this.value = 'ordinary'
          this.percentage = 0
          this.ztTop = 100//钻头位置
          this.image = 'stop'
          this.pileStatusStr = '停钻'
          this.pileDownStatus = '结束'
          this.pulpStatus = '止喷'
          this.recordStatusClass = ''
          this.pipeStatusClass = ''
          this.nozzleStatusClass = ''
          this.s1 = 0
          this.s2 = 0
          this.y = 0
          this.playbackTimeDepth = []
          this.playbackTimeFlow = []
          this.dataNum = []
          this.PlaybackTime = ''
          this.LastTime = 0
          this.totalPartPulp = 0
          this.totalPartAsh = 0
          // this.pileDeep = 0
          // this.totalPartTime = 0
          clearInterval(this.playerInter)
          clearInterval(this.playerInter2)

          this.PlaybackTime = this.selectItem.beginTime
          this.playerInter = setInterval(() => {
            this.backTimeInterval1()
          }, 3000)
          this.playerInter2 = setInterval(() => {
            this.backTimeInterval2()
          }, 3000)
        } else {
          let T = 3000
          if (this.value == 'ordinary') {
            T = 3000
          } else if (this.value == 'fast') {
            T = 2000
          } else if (this.value == 'topspeed') {
            T = 1000
          }
          this.playerInter = setInterval(() => {
            this.backTimeInterval1()
          }, T)
          this.playerInter2 = setInterval(() => {
            this.backTimeInterval2()
          }, T)
        }
        this.icon = 'el-icon-video-pause'
      }
    },
    // 重播按钮
    stop() {
      this.value = 'ordinary'
      this.percentage = 0
      this.ztTop = 100//钻头位置
      this.s1 = 0
      this.s2 = 0
      this.y = 0
      this.playbackTimeDepth = []
      this.playbackTimeFlow = []
      this.dataNum = []
      this.PlaybackTime = ''
      this.LastTime = 0
      this.totalPartPulp = 0
      this.totalPartAsh = 0
      clearInterval(this.playerInter)
      clearInterval(this.playerInter2)
      this.PlaybackTime = this.selectItem.beginTime
      this.playerInter = setInterval(() => {
        this.backTimeInterval1()
      }, 3000)
      this.playerInter2 = setInterval(() => {
        this.backTimeInterval2()
      }, 3000)
    },
    // 详情点击
    handleCommand(index) {
      // 数据暂存在selectItem中
      this.selectItem = this.tableData[index];
      this.print = this.tableData[index].print
      this.centerDialogVisible = true;
      var day = this.selectItem.beginTime
      var time
      var pulp25 = []
      var pulp50 = []
      var pulp75 = []
      var pulp100 = []
      var num = []
      var current = []
      // var initialdeep = 0
      let map = new Map();
      let maps = new Map();
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        day += this.tableData[index].data[i].partTime
        time = moment(day * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.echartsdata.push(time)

        if (this.tableData[index].data[i].partDeep != 0) {
          this.depthReport.push(Math.ceil(this.tableData[index].data[i].partDeep))
        }
        // 数组去重   
        for (var k = 0; k < this.depthReport.length; k++) {
          for (let j = k + 1; j < this.depthReport.length; j++) {
            if (this.depthReport[k] == this.depthReport[j]) {//第一个等同于第二个，splice方法删除第二个
              this.depthReport.splice(j, 1);
              j--;
            }
          }
        }
        if (this.tableData[index].data[i].partDeep == Math.floor(this.tableData[index].data[i].partDeep) + 0.25) {
          let obj = { key: Math.floor(this.tableData[index].data[i].partDeep) + 0.25, value: Number(this.tableData[index].data[i].partPulp.toFixed(2)) }
          pulp25.push(obj)
          var result = {};
          pulp25.forEach(item => {
            if (result[item.key]) {
              result[item.key] += item.value;
            } else {
              result[item.key] = item.value;
            }
          })
        }

        if (this.tableData[index].data[i].partDeep == Math.floor(this.tableData[index].data[i].partDeep) + 0.5) {
          let obj1 = { 'key': Math.floor(this.tableData[index].data[i].partDeep) + 0.5, 'value': Number(this.tableData[index].data[i].partPulp.toFixed(2)) }
          pulp50.push(obj1)
          var results = {};
          pulp50.forEach(item => {
            if (results[item.key]) {
              results[item.key] += item.value;
            } else {
              results[item.key] = item.value;
            }
          })
        }
        if (this.tableData[index].data[i].partDeep == Math.floor(this.tableData[index].data[i].partDeep) + 0.75) {
          let obj2 = { 'key': Math.floor(this.tableData[index].data[i].partDeep) + 0.75, 'value': Number(this.tableData[index].data[i].partPulp.toFixed(2)) }
          pulp75.push(obj2)
          var resultss = {};
          pulp75.forEach(item => {
            if (resultss[item.key]) {
              resultss[item.key] += item.value;
            } else {
              resultss[item.key] = item.value;
            }
          })
        }
        if (this.tableData[index].data[i].partDeep == Math.floor(this.tableData[index].data[i].partDeep)) {
          let obj3 = { 'key': Math.floor(this.tableData[index].data[i].partDeep), 'value': Number((this.tableData[index].data[i].partPulp).toFixed(2)) }
          pulp100.push(obj3)
          var result100 = {};
          pulp100.forEach(item => {
            if (result100[item.key]) {
              result100[item.key] += item.value;
            } else {
              result100[item.key] = item.value;
            }
          })
        }
        // 段灰量数据
        if (num[Math.ceil(this.tableData[index].data[i].partDeep - 1)] == undefined) {
          num[Math.ceil(this.tableData[index].data[i].partDeep - 1)] = 0
        }
        num[Math.ceil(this.tableData[index].data[i].partDeep - 1)] += Number(this.tableData[index].data[i].partAsh.toFixed(2))
        // 段电流数据
        if (current[Math.ceil(this.tableData[index].data[i].partDeep - 1)] == undefined) {
          current[Math.ceil(this.tableData[index].data[i].partDeep - 1)] = 0
        }
        current[Math.ceil(this.tableData[index].data[i].partDeep - 1)] = Number(this.tableData[index].data[i].partAirPressure.toFixed(2))
        // 下钻速度 
        if (this.tableData[index].data[i].partDeep > 0 && this.tableData[index].data[i].partDownSpeed > 0) {
          if (map.get(Math.ceil(this.tableData[index].data[i].partDeep)) == undefined) {
            let deeep = []
            deeep.push(this.tableData[index].data[i].partDownSpeed)
            map.set(Math.ceil(this.tableData[index].data[i].partDeep), deeep)
          } else {
            let deeep = map.get(Math.ceil(this.tableData[index].data[i].partDeep))
            deeep.push(this.tableData[index].data[i].partDownSpeed)
            map.set(Math.ceil(this.tableData[index].data[i].partDeep), deeep)
          }
        }
        // 提钻
        if (this.tableData[index].data[i].partDownSpeed < 0) {
          if (maps.get(Math.ceil(this.tableData[index].data[i].partDeep)) == undefined) {
            let deeep = []
            deeep.push(this.tableData[index].data[i].partDownSpeed)
            maps.set(Math.ceil(this.tableData[index].data[i].partDeep), deeep)
          } else {
            let deeep = maps.get(Math.ceil(this.tableData[index].data[i].partDeep))
            deeep.push(this.tableData[index].data[i].partDownSpeed)
            maps.set(Math.ceil(this.tableData[index].data[i].partDeep), deeep)
          }
        }
      }
      var currents = []
      for (let a = 0; a < current.length; a++) {
        let obj = {
          key: a,
          value: current[a]
        }
        currents.push(obj)
      }
      currents.forEach(item => {
        if (this.partcurrent[item.key]) {
          this.partcurrent[item.key] += item.value;
        } else {
          this.partcurrent[item.key] = item.value;
        }
      })
      // 提钻速度
      var upspeed = []
      maps.forEach(function (key) {
        upspeed.push(key)
      })

      var averageupSpeed = []
      var upnum = 0
      for (let a = 0; a < upspeed.length; a++) {
        for (let s = 0; s < upspeed[a].length; s++) {
          upnum += upspeed[a][s]
        }
        let averageup = Math.abs((upnum / upspeed[a].length).toFixed(2))
        averageupSpeed.push(averageup)
        upnum = 0
      }
      averageupSpeed = averageupSpeed.reverse()
      var up = []
      for (let a = 0; a < averageupSpeed.length; a++) {
        let obj = {
          key: a,
          value: averageupSpeed[a]
        }
        up.push(obj)
      }
      up.forEach(item => {
        if (this.upspeed[item.key]) {
          this.upspeed[item.key] += item.value;
        } else {
          this.upspeed[item.key] = item.value;
        }
      })
      // 下钻速度
      var downdeep = []
      map.forEach(function (key) {
        downdeep.push(key)
      })
      var averagedownSpeed = []
      var nums = 0
      for (let a = 0; a < downdeep.length; a++) {
        for (let s = 0; s < downdeep[a].length; s++) {
          nums += downdeep[a][s]
        }
        let average = (nums / downdeep[a].length).toFixed(2)
        averagedownSpeed.push(average)
        nums = 0
      }
      var down = []
      for (let a = 0; a < averagedownSpeed.length; a++) {
        let obj = {
          key: a,
          value: averagedownSpeed[a]
        }
        down.push(obj)
      }
      down.forEach(item => {
        if (this.downspeed[item.key]) {
          this.downspeed[item.key] += item.value;
        } else {
          this.downspeed[item.key] = item.value;
        }
      })
      // 段灰量
      var ash = [];
      for (let k = 0; k < num.length; k++) {
        let obj = {
          key: k,
          value: num[k]
        }
        ash.push(obj)
      }
      ash.forEach(item => {
        if (this.ashReport[item.key]) {
          this.ashReport[item.key] += item.value;
        } else {
          this.ashReport[item.key] = item.value;
        }
      })
      this.depthReport.unshift(0)
      this.depthReport.splice(this.depthReport.length - 1, 1)
      if (result == undefined) {
        this.pulpReport = []
      } else {
        this.pulpReport = Object.assign(result, results, resultss, result100)
      }

      var startTime = this.selectItem.beginTime
      var serialNumber = []
      for (var j = 0; j < this.selectItem.data.length; j++) {
        this.selectItem.data[j].startDay = moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        startTime += this.selectItem.data[j].partTime
        if (startTime == this.selectItem.data[j]) {
          this.selectItem.data[j].serial = serialNumber.push(j)
        }
      }
    },
    // 选中发生变化
    selectionChange(e) {
      this.GYXPreport = e
      for (let i = 0; i < this.GYXPreport.length; i++) {
        if (this.GYXPreport[i].designDepth == undefined) {
          this.GYXPreport[i].pileDepth = this.GYXPreport[i].depth
        } else if (this.GYXPreport[i].designDepth != undefined && this.GYXPreport[i].depth == 0) {
          this.GYXPreport[i].pileDepth = this.GYXPreport[i].designDepth
        } else {
          this.GYXPreport[i].pileDepth = this.GYXPreport[i].depth
        }
        // 1+2*灰/3*浆 水泥浆比重
        if (this.GYXPreport[i].cumulativePulp == 0) {
          this.GYXPreport[i].WaterPulpRatio = 0
        } else {
          this.GYXPreport[i].WaterPulpRatio = Number((1 + (2 * this.GYXPreport[i].cumulativeAsh) / (3 * this.GYXPreport[i].cumulativePulp)).toFixed(2))
        }
        if (this.GYXPreport[i].cumulativePulp == 0) {
          this.GYXPreport[i].flow = 0
        } else {
          this.GYXPreport[i].flow = Number((this.GYXPreport[i].cumulativePulp / this.GYXPreport[i].pulpTime * 60).toFixed(2))
        }
      }
      if (this.GYXPreport.length != 0) {
        this.GYXPZreportPile = e[0].deviceKey
        this.GYXPZbegintime = moment(e[e.length - 1].beginTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.GYXPZendtime = moment(e[0].endTime * 1000).format('YYYY-MM-DD HH:mm:ss')
      }

      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
    myFun(y) {
      var that = this;
      that.y = Number(y).toFixed(2);
      that.ztTop = (100 - y / this.selectItem.depth.toFixed(2) * 100) + '%';
    },
    // 天地图控件
    loadmap() {
      var T = window.T;
      this.map = new T.Map("mapDiv");
      //设置显示地图的中心点和级别
      this.map.centerAndZoom(new T.LngLat(this.selectItem.longitude, this.selectItem.latitude), 10);
      const ctrl = new T.Control.MapType([{
        title: '地图',
        icon: 'http://api.tianditu.gov.cn/v4.0/image/map/maptype/vector.png', //地图控件上所要显示的图层图标（默认图标大小80x80）
        layer: window.TMAP_NORMAL_MAP
      }, {
        title: '卫星',
        icon: 'http://api.tianditu.gov.cn/v4.0/image/map/maptype/satellite.png',
        layer: window.TMAP_SATELLITE_MAP
      }]);
      // 6.将控件添加到地图，一个控件实例只能向地图中添加一次。
      this.map.addControl(ctrl);
      // 创建坐标
      const point = new T.LngLat(this.selectItem.longitude, this.selectItem.latitude);
      // 8.创建覆盖使用的图标
      const icon = new T.Icon({
        iconUrl: require("@/assets/images/map/b4.png"),
      });
      // 9. 创建在该坐标上的一个图像标注实例
      const marker = new T.Marker(point, icon);
      // 10.将覆盖物添加到地图中，一个覆盖物实例只能向地图中添加一次
      this.map.addOverLay(marker);
      //允许鼠标双击放大地图
      this.map.enableScrollWheelZoom();
    },
  }
}
</script>
<style scoped>
.m-statusinfo .bottom {
  display: flex;
  justify-content: space-between;
}
.mapDiv {
  padding: 0.75rem 1.25rem;
  height: 100%;
}
.backplayBottom {
  background: #fff;
  border-radius: 10px;
  margin-top: 5px;
  height: 4rem;
  display: flex;
  justify-content: space-between;
}
.iconBack {
  color: #000;
  /* color: #409eff; */
  font-size: 26px;
  font-weight: bold;
  line-height: 4rem;
}
.progress_bar {
  width: 75%;
  margin-top: 25px;
}
.selectspeed {
  margin-top: 15px;
  width: 120px;
}
/* >>> .el-input--small .el-input__inner {
  color: #409eff;
} */

.play_back {
  height: 50%;
  display: flex;
  justify-content: space-between;
}
.m-statusinfo {
  background: #fff;
}

.play_col {
  background: #fff;
  margin: 5px;
  border-radius: 10px;
}
.backEchart {
  height: 18.3rem;
}
.m-datab1 {
  padding-top: 0.75rem;
}
.m-datab1 .top {
  margin-bottom: 0;
}

.picbox .data {
  bottom: 96%;
}
.picbox .databar {
  bottom: 10px;
}
/* 回放样式end */
/* echarts图表 */
.m-tab-echartTable {
  position: relative;
}
.outEcharts {
  position: absolute;
  top: 0;
  left: -2000px;
  height: 600px;
  width: 600px;
}
.export_echart {
  cursor: pointer;
  font-size: 12px;
  background: #409eff;
  text-align: center;
  width: 80px;
  height: 32px;
  line-height: 32px;
  margin-top: 14px;
  border-radius: 4px;
  color: #fff;
  float: left;
  margin-right: 10px;
}

.col_3 {
  width: 100%;
}

.ul-detail li {
  width: 33%;
}
.wp {
  width: 100%;
}
.el-button--primary {
  margin-left: 25px;
}
.el-checkbox {
  width: 20%;
}
.m-filter .filter .btn {
  margin-left: 2.5rem;
}
.el-button--primary {
  margin-left: 45px;
  margin-right: 5px;
}
/* 设置详情弹出框详细数据的最小宽度 */
.ul-detail .tit {
  min-width: 8em;
}
.ul-detail .s3 .tit,
.ul-detail .s4 .tit {
  min-width: 10em;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.4rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.4rem;
}
</style>
