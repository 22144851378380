<template>
  <div class="row-history">
    <component :is="report" :dayTimeStart='dayTimeStart' :dayTimeEnd='dayTimeEnd' :repdevice='repdevice' :waterCementRatio='waterCementRatio' :GFZreportData='GFZreportData' :selectItem='selectItem' :Reportdepth="Reportdepth" :ReportData="ReportData" :downSpeedReport="downSpeedReport" :upSpeedReport="upSpeedReport"></component>
    <a id="simulation" href=""></a>
    <el-container class="row-con">
      <el-header class="m-filter">
        <div class="wp">
          <div class="filter">
            <el-form ref="filter" class="m-filterbox">
              <div class="right">
                <el-button @click="querychxun" class="btn btn-search" type="primary" icon="el-icon-search">查询
                </el-button>
                <el-button class="btn btn-reset" native-type="reset" @click="resetClick">
                  <span class="icon icon-reset"></span><span>重置</span>
                </el-button>
                <el-button type="primary" @click="dialogFormVisible = true">选择参数</el-button>
              </div>
              <div class="left">
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="startDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <div class="item">至</div>
                <el-form-item class="item g-date">
                  <el-col :span="14" class="date">
                    <el-date-picker value-format="timestamp" type="datetime" prefix-icon="" v-model="endDate" placeholder="选择日期" style="width: 100%;">
                    </el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item class="item picker picker-device">
                  <!-- filterable multiple  -->
                  <el-select v-model="deviceKey" filterable multiple reserve-keyword placeholder="请选择设备">
                    <el-option v-for="item in deviceList" :key="item.key" :label="item.name" :value="item.key">
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item class="item input">
                  <el-input class="input" placeholder="请输入桩号" v-model="number"></el-input>
                </el-form-item>
                <el-select class="selectLevel" v-model="queryStatus" placeholder="预警状态">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-form>
          </div>
        </div>
      </el-header>
      <!-- table表格 -->
      <el-main class="m-table-main">
        <div id="table-content">
          <el-table :cell-style="backgroundColor" :data="tableData" style="width: 100%" ref="table" row-key="id" @selection-change="selectionChange" :tree-props="{ children: 'children', hasChildren: 'hasChildren' }">
            <el-table-column type="selection" align='center' fixed></el-table-column>
            <el-table-column v-for="(col,index) in cols" :key="index" :prop="col.prop" :label="col.label" :formatter="col.formatter" min-width='155px' align='center' show-overflow-tooltip> </el-table-column>
            <el-table-column fixed="right" label="详情">
              <template slot-scope="scope">
                <div class="m-edit-btns">
                  <div class="item">
                    <a href="javascript:" class="btn" @click="handleCommand(scope.$index)">详情</a>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column property="status" fixed="right" label="状态" min-width="90px">
              <template slot-scope="scope">{{scope.row.status|statusFilter}}</template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <div class="m-edit-btns">
                  <div class="item"><a href="javascript:" class="btn" @click="alertInfo(scope.$index)">处理</a></div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-main>
      <!-- 选择参数弹窗 -->
      <el-dialog title="选择需要展示的参数" :visible.sync="dialogFormVisible" width="40%" center top="15vh">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <div style="margin: 15px 0;"></div>
        <el-checkbox-group v-model="checkedFileds" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in fieldNames" :label="item.prop" :key="item.prop">{{ item.label }}</el-checkbox>
        </el-checkbox-group>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" size="medium">取 消</el-button>
          <el-button type="primary" @click="confirmShow" size="medium">确定</el-button>
        </div>
      </el-dialog>
    </el-container>
    <!-- 图片上传弹框 -->
    <el-dialog :visible.sync="dialogUserInfo" width="50%">
      <div class="upload-demo">
        <el-upload :file-list="UploadPath" :on-preview="onPreview" accept=".jpg,.jpeg,.png" :multiple="true" :before-upload="beforeImgUpload" :on-remove="handleRemove" :on-success="success" action="/api/file/upload" :headers="headers" list-type="picture-card" :disabled="UploadDisable">
          <i class="el-icon-plus"></i>
        </el-upload>
      </div>
      <div class="dialogBot">
        <el-input clear="upInput" v-model="alRemarks" autosize type="textarea" placeholder="请输入内容"></el-input>
        <div class="uploadBtn">
          <el-button :disabled="disabled" v-show="showButton" size="small" type="primary" @click="uploadQD()">{{imgUploadButton}}</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="Imgdialog" width="50%" center top="15vh">
      <img style="width:100%" :src="ImgSrc" alt="">
    </el-dialog>
    <!-- 弹出框     -->
    <el-dialog :visible.sync="centerDialogVisible" class="m-pop m-pop-detail" v-el-dialog-drag>
      <div slot="title" class="dialog-header">
        <div class="left">
          <gTabs @change="changeHandle" :current="tab2" :list="tab2List" />
        </div>
        <div class="right">
          <el-button size="small" type="primary" v-show="showechart" @click="downloadEchart">图片下载</el-button>
          <el-dropdown trigger="click" placement="bottom-start" @command="downLoad">
            <span class="more ">报表下载</span>
            <el-dropdown-menu slot="dropdown" class="g-dropdown">
              <el-dropdown-item :command="0">
                <span class="icon icon01"></span>
                <span class="name">报表导出</span>
                <!-- <el-link :href='reportherf' :underline='false'>报表下载</el-link> -->
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <compoent :is="report" :selectItem="selectItem"></compoent>
      <!-- 详细数据 -->
      <div class="dialog-content">
        <div class="m-tab-table m-tab-detail" v-show="tab2 === 0">
          <div class="m-table-detail">
            <div class="detailitem">
              <h3>详细数据</h3>
              <div class="box">
                <ul class="ul-detail">
                  <li class="s1">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">桩号</div>
                        <div class="value" :title=this.selectItem.pileDescribe>{{selectItem.pileDescribe}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">桩机编号</div>
                        <div class="value">{{selectItem.deviceKey}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">开始时间</div>
                        <div class="value">{{selectItem.beginTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">结束时间</div>
                        <div class="value">{{selectItem.endTime|datafilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">施工桩长</div>
                        <div class="value">{{selectItem.depth|numfilters}} (m)</div>
                      </div>
                      <div class="item">
                        <div class="tit">成桩时间</div>
                        <div class="value">{{selectItem.pileTime|minfilters}} (min)</div>
                      </div>
                    </div>
                  </li>
                  <li class="s2">
                    <div class="inner">
                      <div class="item">
                        <div class="tit">平均浆量(L/m)</div>
                        <div class="value">{{selectItem.averagePulp|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计浆量(L)</div>
                        <div class="value">{{selectItem.cumulativePulp|numfilters(0)}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计浆量1(L)</div>
                        <div class="value">{{selectItem.cumulativePulp1|numfilters(0)}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计浆量2(L)</div>
                        <div class="value">{{selectItem.cumulativePulp2|numfilters(0)}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计浆量3(L)</div>
                        <div class="value">{{selectItem.cumulativePulp3|numfilters(0)}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">累计灰量(Kg)</div>
                        <div class="value">{{selectItem.cumulativeAsh|numfilters(0)}}</div>
                      </div>
                      <!-- <div class="item">
                        <div class="tit">累计浆量4(L)</div>
                        <div class="value">{{selectItem.cumulativePulp4|numfilters(0)}}</div>
                      </div> -->
                    </div>
                  </li>
                  <li class="s3">
                    <div class="inner">
                      <!-- <div class="item">
                        <div class="tit">累计浆量5(L)</div>
                        <div class="value">{{selectItem.cumulativePulp5|numfilters(0)}}</div>
                      </div> -->
                      <div class="item">
                        <div class="tit">水灰比(:1)</div>
                        <div class="value">{{selectItem.waterCementRatio|numfilters(1)}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">最大斜度(%)</div>
                        <div class="value">{{selectItem.maxAngle|numfilters(1)}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">下钻速度(cm/min)</div>
                        <div class="value">{{selectItem.downSpeed|numfilters}}</div>
                      </div>
                      <div class="item">
                        <div class="tit">提钻速度(cm/min)</div>
                        <div class="value">{{selectItem.upSpeed|numfilters}}</div>
                      </div>

                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <br />
            <h3>段数据列表</h3>
            <el-table ref="saveTable" class="m-table-save m_table_save_height" :data="selectItem.data" style="width: 100%;margin-top:20px" height="28em">
              <el-table-column property="startDay" label="时间" min-width="108px" align="center" fixed>
              </el-table-column>
              <el-table-column property="partDeep" :label="'段深度\nm'" min-width="86px" align="center">
                <template slot-scope="scope">
                  {{scope.row.partDeep|numfilters(2)}}
                </template>
              </el-table-column>
              <el-table-column property="partDownSpeed" :label="'段速度\ncm/min'" min-width="86px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|num3filters }}
                </template>
              </el-table-column>
              <el-table-column property="partPulp1" :label="'段浆量1\nL'" min-width="86px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPulp1|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="partPulp2" :label="'段浆量2\nL'" min-width="86px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPulp2|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="partPulp3" :label="'段浆量3\nL'" min-width="86px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partPulp3|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="partAsh" :label="'段灰量\nkg'" min-width="86px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partAsh|numfilters(1) }}
                </template>
              </el-table-column>
              <el-table-column property="partDensity" :label="'段密度\ng/cm³'" min-width="86px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDensity|numfilters(3) }}
                </template>
              </el-table-column>
              <el-table-column property="partDownSpeed" label="状态" min-width="57px" align="center">
                <template slot-scope="scope">
                  {{ scope.row.partDownSpeed|num4filters }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="m-tab-table m-tab-echartTable" v-show="tab2 === 1">
          <div class="m-pop-echart">
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart01" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart03" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
            <el-row class="row">
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart02" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
              <el-col :span="12" class="col">
                <div class="inner">
                  <div ref="myChart04" :style="{ width: '100%', height: '100%' }"></div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div class="reportEchart">
            <div ref="myChart06" :style="{width:'100%',height:'33.3%'}"></div>
            <div ref="myChart07" :style="{width:'100%',height:'33.3%'}"></div>
            <div ref="myChart08" :style="{width:'100%',height:'33.3%'}"></div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-footer class="m-selectAll">
      <div class="left">
        <div class="item">
          <el-checkbox v-model="totalChecked" class="total" @change="totalChange">
            全选
          </el-checkbox>
        </div>
        <div class="item">
          <div class="export" id="simulations" href="" @click="exportAll">导出
          </div>
        </div>
      </div>
      <div class="right">
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot,prev, pager, next" :total="total">
          <!-- slot部分，跳转首页 -->
          <button class="lastPage" @click="toFirstPage">
            <i class="el-icon-d-arrow-left"></i>
          </button>
        </el-pagination>
        <el-pagination class="pages" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 50,100]" :page-size="pageSize" layout="slot, jumper, sizes" :total="total">
          <!-- slot部分，跳转末页 -->
          <button class="lastPage" @click="toLastPage">
            <i class="el-icon-d-arrow-right"></i>
          </button>
        </el-pagination>
      </div>
    </el-footer>
  </div>
</template>
<script>
import tableToExcel from '../../utils/report'
import report from '../report/report.vue'
import { http, show } from '../../main'
import moment from "moment";
import { historyWarns, editWarn } from "@/api/api"
import gTabs from "@/components/gTabs";
import * as echarts from "echarts";
const allField = ['pileDescribe', 'deviceName', 'deviceKey', 'beginTime', 'endTime', 'depth', 'pileTime', 'waterCementRatio', 'cumulativePulp',
  'averagePulp', 'maxAngle', 'cumulativeAsh', 'maxDownSpeed', 'downSpeed', 'upSpeed', 'maxUpSpeed', 'emDepth', 'warnreason'] //所有的数据
export default {
  components: {
    gTabs, report
  },
  props: ['tableData', 'device', 'deviceList', 'pageSize', 'total', 'currentPage', 'handleSizeChange', 'toLastPage', 'toFirstPage', 'handleCurrentChange'],
  data() {
    return {
      reportherf: '',
      report: 'report',
      // flag: false,
      totalChecked: false,
      showechart: false,
      checkAll: false,  //默认全选
      isIndeterminate: false, // 默认全选
      dialogFormVisible: false, //弹窗
      centerDialogVisible: false,// 弹窗
      number: "",//输入桩号 input框
      deviceKey: '', //选择设备下拉框
      startDate: '',
      endDate: '',
      // currentMpa: '',//段数据电流、压力
      // unit: '', //单位
      checkedFileds: ['pileDescribe', 'deviceName', 'beginTime', 'depth', 'cumulativeAsh', 'downSpeed', 'upSpeed', 'maxAngle', 'warnreason'], //选择参数 默认选中的数据
      fieldNames: [
        { prop: 'pileDescribe', label: '桩号' },
        { prop: 'deviceName', label: '桩机名称' },
        { prop: 'deviceKey', label: '桩机号' },
        { prop: 'beginTime', label: '开始时间', formatter: this.testFormatter, },
        { prop: 'endTime', label: '结束时间', formatter: this.testFormatter, },
        { prop: 'depth', label: '施工桩长(m)', formatter: this.numFormatter },
        { prop: 'cumulativePulp', label: '累计浆量(L)', formatter: this.num0Formatter },
        { prop: 'averagePulp', label: '平均浆量(L)', formatter: this.numFormatter },
        { prop: 'cumulativeAsh', label: '累计灰量(Kg)', formatter: this.num0Formatter },
        { prop: 'downSpeed', label: '下钻速度(cm/min)', formatter: this.num2Formatter },
        { prop: 'upSpeed', label: '提钻速度(cm/min)', formatter: this.num2Formatter },
        { prop: 'maxDownSpeed', label: '最大钻速(cm/min)', formatter: this.num2Formatter },
        { prop: 'maxUpSpeed', label: '最大提速(cm/min)', formatter: this.num2Formatter },
        { prop: 'emDepth', label: '空搅(m)', formatter: this.emdepth },
        { prop: 'waterCementRatio', label: '水灰比(:1)', formatter: this.numFormatter },
        { prop: 'maxAngle', label: '最大斜度(%)', formatter: this.numFormatter },
        { prop: 'pileTime', label: '成桩时间(min)', formatter: this.minFormatter },
        { prop: 'warnreason', label: '预警原因', }
      ],   //列表对象数组全部的数据
      cols: [
        { prop: 'pileDescribe', label: '桩号' },
        { prop: 'deviceName', label: '桩机名称' },
        { prop: 'beginTime', label: '开始时间', formatter: this.testFormatter, },
        { prop: 'depth', label: '桩长(m)', formatter: this.numFormatter },
        { prop: 'cumulativeAsh', label: '累计灰量(kg)', formatter: this.num0Formatter },
        { prop: 'upSpeed', label: '提钻速度(cm/min)', formatter: this.numFormatter },
        { prop: 'downSpeed', label: '下钻速度(cm/min)', formatter: this.numFormatter },
        { prop: 'maxAngle', label: '最大斜度(%)', formatter: this.numFormatter },
        { prop: 'warnreason', label: '预警原因', },
      ],//默认显示的数据
      tempCols: [],  //选择参数按钮 临时的数据容器
      echartsdata: [],
      selectItem: [], //详情数据
      tab2: 0,
      tab2List: [{ name: "详细内容", }, { name: "图表曲线", }],
      //查询数据
      inquire: [],
      totalNum: 0,
      //查询数据
      // 图片下载
      echartsConnect: [],
      echartsConnect2: [],
      // 图片下载
      // 导出数据
      Reportdepth: [],
      ReportData: [],
      downSpeedReport: [],
      upSpeedReport: [],
      GFZreportData: [],
      dayTimeStart: '',
      dayTimeEnd: '',
      repdevice: '',
      waterCementRatio: 0,
      // 导出数据
      // 预警数据
      options: [{
        value: '',
        label: '全部预警'
      }, {
        value: -2,
        label: '待处理'
      }, {
        value: 2,
        label: '已通过检查'
      }],
      queryStatus: '',
      headers: { token: sessionStorage['token'] },
      Imgdialog: false,
      dialogUserInfo: false,
      UploadPath: [],
      UploadDisable: false,
      disabled: false,
      showButton: true,
      imgUploadButton: '提交',
      alRemarks: '',
      ImgSrc: '',
      alertHandleData: [],
      fileList: [],
    }
  },
  filters: {
    // 预警状态
    statusFilter(val) {
      if (val == -1) {
        return '已处理，待审核'
      } if (val == 0) {
        return '审核'
      } if (val == 1) {
        return '复核'
      } if (val == 2) {
        return '已通过检查'
      } else {
        return '待处理'
      }
      //  if (val == 1) {
      //   return '复核'
      // } 
    },
    datafilters(val) {
      return moment(val * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numfilters(val, deg) {
      if (deg == undefined) {
        deg = 2
      }
      if (isNaN(val)) {
        //val 不是数字
        return val
      } else {
        //val是数字
        return parseFloat(val).toFixed(deg)
      }
    },
    minfilters(val) {
      return parseInt(val / 60)
    },
    num3filters(val) {
      return Math.abs(val.toFixed(1))
    },
    num4filters(val) {
      return val > 0 ? '下钻' : '提钻'
    }
  },
  watch: {
    centerDialogVisible(val, oldval) {
      window.onresize = null
      if (val == false) {
        this.showechart = false
        this.tab2 = 0
        this.selectItem = []
        this.echartsdata = []
        this.Reportdepth = []
        this.ReportData = []
        this.downSpeedReport = []
        this.upSpeedReport = []
        if (this.myChart01 === undefined || this.myChart01._disposed) return
        this.myChart01.dispose()
        this.myChart02.dispose()
        this.myChart03.dispose()
        this.myChart04.dispose()
        this.myChart06.dispose()
        this.myChart07.dispose()
        this.myChart08.dispose()
      }
    },
    dialogUserInfo(val, oldval) {
      if (val == false) {
        this.UploadPath = []
        this.fileList = []
        this.alRemarks = ''
      }
    }
  },
  mounted() {
    if (this.device == '') {
      this.deviceKey = ''
    } else {
      this.deviceKey = [this.device]
    }
  },
  methods: {
    // 预警处理弹出框
    alertInfo(index) {
      this.dialogUserInfo = true
      this.alertHandleData = this.tableData[index]
      if (this.alertHandleData.filePaths != undefined || this.alertHandleData.remarks != undefined) {
        this.UploadPath = this.alertHandleData.filePaths
        this.alRemarks = this.alertHandleData.remarks[0]
      }
      if (this.alertHandleData.status == 2) {
        this.imgUploadButton = '已闭合'
        this.disabled = true
      } else {
        this.imgUploadButton = '提交'
        this.disabled = false
      }
    },
    beforeImgUpload(file) {
      const isJPG = file.type === "image/jpg";
      const isPng = file.type === "image/png";
      const isJpeg = file.type === "image/jpeg";

      const is10M = (file.size / 1024 / 1024) < 10;
      //限制文件上传类型
      if (!isJPG && !isPng && !isJpeg) {
        this.$message.error("上传图片只能是 png,jpg,jpeg 格式!");
        return false
      }
      //限制文件上传大小
      if (!is10M) {
        this.$message.error("上传图片大小不能超过 10MB!");
        return false
      }
      return true
    },
    // 图片删除
    handleRemove(file, fileList) {
      this.fileList = [];
      if (fileList.length != 0) {
        for (let i = 0; i < fileList.length; i++) {
          let obj = {
            url: fileList[i].url
          }
          this.fileList.push(obj)
        }
      }
    },
    // 上传成功
    success(response) {
      let obj = {
        url: response
      }
      this.fileList.push(obj)
    },
    // 上传确定按钮
    uploadQD() {
      let uploadData = { 'a': this.fileList, 'b': [this.alRemarks] }
      this.$confirm('确定提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        editWarn(this.alertHandleData._id, uploadData, 2).then(() => {
          historyWarns('GFZ', this.currentPage - 1, this.device, this.pageSize, this.number, this.queryStatus, this.startDate, this.endDate).then((res) => {
            this.inquire = res.content
            this.totalNum = res.totalElements
            this.warnReason()
            this.$emit('changeDevicekey', this.device, this.number, this.inquire, this.queryStatus, this.startDate, this.endDate, this.totalNum)
          });
        })
        this.$message({ type: 'success', message: '上传成功!' });
        this.dialogUserInfo = false
      }).catch(() => {
        this.$message({ type: 'info', message: '上传已取消' });
        this.dialogUserInfo = false
      });
    },
    // 点击预览
    onPreview(scope) {
      this.Imgdialog = true
      this.ImgSrc = scope.url
    },


    // 设置列的背景颜色
    backgroundColor(row, column, rowIndex, columnIndex) {
      if (row.column.property == "status") {
        if (row.row.status != 2) {
          return { color: '#ff3333' }
        }
      }
      if (row.column.property == "upSpeed") {
        if (row.row.upSpeedError >= 0.3) {
          return { color: '#ff3333' }
        }
        if (row.row.upSpeedError < 0.3 && row.row.upSpeedError > 0) {
          return { color: '#DAA520' }
        }
      }
      if (row.column.property == "cumulativeAsh") {
        if (row.row.ashError >= 0.3) {
          return { color: '#ff3333' }
        }
        if (row.row.ashError < 0.3 && row.row.ashError > 0) {
          return { color: '#DAA520' }
        }
      }
      if (row.column.property == "downSpeed") {
        if (row.row.downSpeedError >= 0.3) {
          return { color: '#ff3333' }
        }
        if (row.row.downSpeedError < 0.3 && row.row.downSpeedError > 0) {
          return { color: '#DAA520' }
        }
      }
      if (row.column.property == 'maxAngle') {
        if (row.row.maxAngle > 0.5) {
          return { color: '#ff3333' }
        }
      }
      if (row.column.property == "depth") {
        if (row.row.depthError >= 0.3) {
          return { color: '#ff3333' }
        }
        if (row.row.depthError < 0.3 && row.row.depthError > 0) {
          return { color: '#DAA520' }
        }
      }
    },
    // echart图表下载
    downloadEchart() {
      let a = document.createElement('a');
      a.href = this.myChart06.getConnectedDataURL({
        pixelRatio: 2,
        backgroundColor: '#fff'
      })
      a.download = "图片下载.png";
      a.click();
    },
    // 报表下载
    downLoad(command) {
      switch (command) {
        case 0:
          tableToExcel('GFZExportTabledetailed', '工法桩_详细版')
          break
      }
    },
    // 导出
    exportAll() {
      tableToExcel('GFZstatisticsTableRecord', '工法桩')
    },
    // 预警原因
    warnReason() {
      for (let i = 0; i < this.inquire.length; i++) {
        this.inquire[i].warnreason = ''
        if (this.inquire[i].depthError > 0) {
          this.inquire[i].warnreason += '桩长不足:' + (this.inquire[i].depthError * 100).toFixed(2) + '%;'
        }
        if (this.inquire[i].ashError > 0) {
          this.inquire[i].warnreason += '灰量不足:' + (this.inquire[i].ashError * 100).toFixed(2) + '%;'
        }
        if (this.inquire[i].upSpeedError > 0) {
          this.inquire[i].warnreason += '提速超标:' + (this.inquire[i].upSpeedError * 100).toFixed(2) + '%;'
        }
        if (this.inquire[i].downSpeedError > 0) {
          this.inquire[i].warnreason += '钻速超标:' + (this.inquire[i].downSpeedError * 100).toFixed(2) + '%'
        }
        if (this.inquire[i].maxAngle > 0.5) {
          this.inquire[i].warnreason += '倾角超标:' + ((this.inquire[i].maxAngle - 0.5) / 0.5 * 100).toFixed(2) + '%'
        }
      }
    },
    // 设备查询
    querychxun() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.deviceKey.length > 0) {
        var device = this.deviceKey.join()
      } else {
        device = ''
      }
      historyWarns('GFZ', 0, device, this.pageSize, this.number, this.queryStatus, this.startDate, this.endDate).then((res) => {
        this.inquire = res.content
        this.totalNum = res.totalElements
        this.warnReason()
        this.$emit('changeDevicekey', device, this.number, this.inquire, this.queryStatus, this.startDate, this.endDate, this.totalNum)
        loading.close();
      });
      setTimeout(() => {
        loading.close();
      }, 6000);
    },
    // 重置按钮
    resetClick() {
      this.deviceKey = ""
      this.number = ""
      this.startDate = ''
      this.endDate = ''
      this.queryStatus = ''
    },

    testFormatter(row, column, cellValue, index) {
      return moment(cellValue * 1000).format('YYYY-MM-DD HH:mm:ss')
    },
    numFormatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(2)
    },
    num0Formatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(0)
    },
    num2Formatter(row, column, cellValue, index) {
      return parseFloat(cellValue).toFixed(1)
    },
    minFormatter(row, column, cellValue, index) {
      return (parseFloat(cellValue) / 60).toFixed(0)
    },
    emdepth(row, column, cellValue, index) {
      if (cellValue == undefined) {
        return '-'
      } else {
        return (parseFloat(cellValue) / 60).toFixed(2)
      }
    },
    initEchart01() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(2)];
      })
      let option = {
        title: {
          text: "深度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度m",
            inverse: true,
            nameLocation: "end",
            type: "value",
            max: 35,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          }
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart01 = echarts.init(this.$refs.myChart01, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart01.setOption(option);
      this.echartsConnect.push(this.myChart01)
    },
    initEchart02() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp1 / item.partTime * 60).toFixed(2)];
      });
      let y21Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp2 / item.partTime * 60).toFixed(2)];
      });
      let y22Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp3 / item.partTime * 60).toFixed(2)];
      })
      let option = {
        title: {
          text: "深度、流量曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "12%",
          right: "10%",
          data: ["深度", "流量1", "流量2", "流量3"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度m",
            inverse: true,
            type: "value",
            nameLocation: "end", // 设置坐标轴名称显示位置
            max: 35,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "流量",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            // max: 350,
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: true
            },

          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "流量1",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
          {
            name: "流量2",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y21Data,
          },
          {
            name: "流量3",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y22Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart02 = echarts.init(this.$refs.myChart02, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart02.setOption(option);
      this.echartsConnect.push(this.myChart02)
    },
    initEchart03() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDownSpeed.toFixed(1)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp1 / item.partTime * 60).toFixed(2)];
      });
      let y21Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp2 / item.partTime * 60).toFixed(2)];
      });
      let y22Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp3 / item.partTime * 60).toFixed(2)];
      });
      let option = {
        title: {
          text: "速度、流量曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "12%",
          right: "10%",
          data: ["速度", "流量1", "流量2", "流量3"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        toolbox: {
          right: "3%",
          top: "3%",
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "速度cm/min",
            inverse: false,
            nameLocation: "start",
            type: "value",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "流量L/min",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: false
            }
          },
        ],
        series: [
          {
            name: "速度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "流量1",
            yAxisIndex: 1, //判断对应的那个轴
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
          {
            name: "流量2",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y21Data,
          },
          {
            name: "流量3",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y22Data,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart03 = echarts.init(this.$refs.myChart03, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart03.setOption(option);
      this.echartsConnect.push(this.myChart03)
    },
    initEchart04() {
      var ash = []
      for (var k = 0; k < this.selectItem.data.length; k++) {
        if (ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] == undefined) {
          ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] = 0
        }
        ash[Math.ceil(this.selectItem.data[k].partDeep - 1)] += this.selectItem.data[k].partAsh
      }
      var numbers2 = ash.map(myFunction);
      function myFunction(value, index, array) {
        var data = {
          value: Number(value.toFixed(2)),
          name: index + 1
        }
        return data;
      }
      let option = {
        title: {
          text: "成桩灰量分布图",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} : {c}kg",
        },
        grid: {
          top: "10%",
          bottom: "10%",
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            name: "灰量分布",
            type: "funnel",
            width: "50%",
            left: "center",
            top: "15%",
            bottom: "8%",
            label: {
              show: true,
            },
            labelLine: {
              show: true,
            },
            data: numbers2,
            sort: "none",
            color: '#5470c6'
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart04 = echarts.init(this.$refs.myChart04, null, { renderer: 'svg' });
      // 绘制图表
      this.myChart04.setOption(option);
    },
    initechart06() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(2)];
      })
      let option = {
        title: {
          text: "深度曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度m",
            inverse: true,
            nameLocation: "end",
            type: "value",
            max: 35,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          }
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart06 = echarts.init(this.$refs.myChart06, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart06.setOption(option);
      this.echartsConnect2.push(this.myChart06)
    },
    initechart07() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDeep.toFixed(2)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp1 / item.partTime * 60).toFixed(2)];
      });
      let y21Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp2 / item.partTime * 60).toFixed(2)];
      });
      let y22Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp3 / item.partTime * 60).toFixed(2)];
      })
      let option = {
        title: {
          text: "深度、流量曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["深度", "流量1", "流量2", "流量3"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "深度m",
            inverse: true,
            type: "value",
            nameLocation: "end", // 设置坐标轴名称显示位置
            max: 35,
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "流量",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            // max: 350,
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: true
            },

          },
        ],
        series: [
          {
            name: "深度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "流量1",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
          {
            name: "流量2",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y21Data,
          },
          {
            name: "流量3",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y22Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart07 = echarts.init(this.$refs.myChart07, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart07.setOption(option);
      this.echartsConnect2.push(this.myChart07)
    },
    initechart08() {
      let data = this.echartsdata;
      let y1Data = this.selectItem.data.map((item, index) => {
        return [data[index], item.partDownSpeed.toFixed(1)];
      });
      let y2Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp1 / item.partTime * 60).toFixed(2)];
      });
      let y21Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp2 / item.partTime * 60).toFixed(2)];
      });
      let y22Data = this.selectItem.data.map((item, index) => {
        return [data[index], (item.partPulp3 / item.partTime * 60).toFixed(2)];
      });
      let option = {
        title: {
          text: "速度、流量曲线",
          textStyle: {
            color: "#101D34",
            fontSize: 16,
          },
          left: "2.2%",
          top: "5%",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          top: "5%",
          right: "10%",
          data: ["速度", "流量1", "流量2", "流量3"],
        },
        grid: {
          top: "22%",
          left: "3%",
          right: "3%",
          bottom: "13%",
          containLabel: true,
        },
        xAxis: {
          type: "time",
          axisLabel: {
            interval: 10,
            formatter: '{HH}:{mm}'
          }
        },
        yAxis: [
          {
            name: "速度cm/min",
            inverse: false,
            nameLocation: "start",
            type: "value",
            axisLine: {
              show: true
            },
            nameTextStyle: {
              padding: [10, 0, 0, 15]
            }
          },
          {
            name: "流量L/min",
            nameLocation: "start", // 设置坐标轴名称显示位置
            inverse: false,
            type: "value",
            nameTextStyle: {
              padding: [10, 15, 0, 0]
            },
            splitLine: {
              show: false
            }
          },
        ],
        series: [
          {
            name: "速度",
            type: "line",
            showSymbol: false,
            data: y1Data,
          },
          {
            name: "流量1",
            yAxisIndex: 1, //判断对应的那个轴
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y2Data,
          },
          {
            name: "流量2",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y21Data,
          },
          {
            name: "流量3",
            yAxisIndex: 1,
            type: "line",
            splitLine: {
              lineStyle: {
                type: "dashed",
              },
            },
            showSymbol: false,
            data: y22Data,
          }
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart08 = echarts.init(this.$refs.myChart08, null, { devicePixelRatio: 2.5 });
      // 绘制图表
      this.myChart08.setOption(option);
      this.echartsConnect2.push(this.myChart08)
    },
    // 详情切换标签
    changeHandle(index) {
      let self = this;
      this.tab2 = index;
      if (index === 0) {
        this.showechart = false
      }
      if (index === 1) {
        this.showechart = true
        this.$nextTick(() => {
          this.initEchart01(); this.initEchart02(); this.initEchart03();
          this.initEchart04();
          echarts.connect(this.echartsConnect)
          this.initechart06(); this.initechart07(); this.initechart08()
          echarts.connect(this.echartsConnect2)
          window.onresize = function () {
            self.myChart01.resize();
            self.myChart02.resize();
            self.myChart03.resize();
            self.myChart04.resize();
          };
        });
      }
    },
    // 历史详情弹出框
    handleCommand(index) {
      // 数据暂存在selectItem中
      this.selectItem = this.tableData[index];
      // this.reportherf=http + "/downLoadDetailExcel?id=" + this.selectItem._id //报表导出地址
      this.centerDialogVisible = true;
      var day = this.selectItem.beginTime
      var time
      for (var i = 0; i < this.tableData[index].data.length; i++) {
        day += this.tableData[index].data[i].partTime
        time = moment(day * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.echartsdata.push(time)
      }
      var startTime = this.selectItem.beginTime
      for (var j = 0; j < this.selectItem.data.length; j++) {
        this.selectItem.data[j].startDay = moment(startTime * 1000).format('HH:mm:ss')
        startTime += this.selectItem.data[j].partTime

        this.Reportdepth.push(Math.ceil(this.selectItem.data[j].partDeep))
      }

      // 导出数据
      let data = this.selectItem.data
      this.Reportdepth = [...new Set(this.Reportdepth)]
      this.Reportdepth.sort((old, New) => {
        return old - New
      }) //深度数组排序
      // 下钻
      let mapDownSpeed = new Map()
      // 提钻
      let mapUpSpeed = new Map()
      // 段数据
      let map = new Map()
      for (let i = 0; i < data.length; i++) {
        if (data[i].partDownSpeed > 0) {
          if (mapDownSpeed.get(Math.ceil(data[i].partDeep)) == undefined) {
            let downSpeed = data[i].partDownSpeed
            mapDownSpeed.set(Math.ceil(data[i].partDeep), downSpeed)
          } else {
            let downSpeedReport
            let downSpeed = mapDownSpeed.get(Math.ceil(data[i].partDeep))
            if (downSpeed > data[i].partDownSpeed || downSpeed == data[i].partDownSpeed) {
              downSpeedReport = downSpeed
            } else {
              downSpeedReport = data[i].partDownSpeed
            }
            mapDownSpeed.set(Math.ceil(data[i].partDeep), downSpeedReport)
          }
        }
        if (data[i].partDownSpeed <= 0) {
          if (mapUpSpeed.get(Math.ceil(data[i].partDeep)) == undefined) {
            let upSpeed = Math.abs(data[i].partDownSpeed)
            mapUpSpeed.set(Math.ceil(data[i].partDeep), upSpeed)
          } else {
            let upSpeedReport
            let upspeed = mapUpSpeed.get(Math.ceil(data[i].partDeep))
            if (upspeed > Math.abs(data[i].partDownSpeed) || upspeed == Math.abs(data[i].partDownSpeed)) {
              upSpeedReport = upspeed
            } else {
              upSpeedReport = Math.abs(data[i].partDownSpeed)
            }
            mapUpSpeed.set(Math.ceil(data[i].partDeep), upSpeedReport)
          }
        }
        if (map.get(Math.ceil(data[i].partDeep)) == undefined) {
          let partPulp1 = data[i].partPulp1
          let partPulp2 = data[i].partPulp2
          let partPulp3 = data[i].partPulp3
          let totalPartPulp = data[i].partPulp1 + data[i].partPulp2 + data[i].partPulp3
          let totalPartAsh = data[i].partAsh
          let partCurrent1 = data[i].partPulpPressure1
          let partCurrent2 = data[i].partPulpPressure2
          let partCurrent3 = data[i].partPulpPressure3
          let time = data[i].partTime
          let flow1 = partPulp1 / time * 60
          let flow2 = partPulp2 / time * 60
          let flow3 = partPulp3 / time * 60
          map.set(Math.ceil(data[i].partDeep), [partPulp1, partPulp2, partPulp3, totalPartPulp, totalPartAsh, partCurrent1, partCurrent2, partCurrent3, time, flow1, flow2, flow3])
        } else {
          let partPulp1 = map.get(Math.ceil(data[i].partDeep))[0]
          partPulp1 += data[i].partPulp1
          let partPulp2 = map.get(Math.ceil(data[i].partDeep))[1]
          partPulp2 += data[i].partPulp2
          let partPulp3 = map.get(Math.ceil(data[i].partDeep))[2]
          partPulp3 += data[i].partPulp3
          let totalPartPulp = map.get(Math.ceil(data[i].partDeep))[3]
          totalPartPulp += (data[i].partPulp1 + data[i].partPulp2 + data[i].partPulp3)
          let totalPartAsh = map.get(Math.ceil(data[i].partDeep))[4]
          totalPartAsh += data[i].partAsh
          let partCurrent1
          let Current1 = map.get(Math.ceil(data[i].partDeep))[5]
          if (Current1 > data[i].partPulpPressure1 || Current1 == data[i].partPulpPressure1) {
            partCurrent1 = Current1
          } else {
            partCurrent1 = data[i].partPulpPressure1
          }
          let partCurrent2
          let Current2 = map.get(Math.ceil(data[i].partDeep))[6]
          if (Current2 > data[i].partPulpPressure2 || Current1 == data[i].partPulpPressure2) {
            partCurrent2 = Current2
          } else {
            partCurrent2 = data[i].partPulpPressure2
          }
          let partCurrent3
          let Current3 = map.get(Math.ceil(data[i].partDeep))[7]
          if (Current3 > data[i].partPulpPressure3 || Current3 == data[i].partPulpPressure3) {
            partCurrent3 = Current3
          } else {
            partCurrent3 = data[i].partPulpPressure3
          }
          let time = map.get(Math.ceil(data[i].partDeep))[8]
          time += data[i].partTime
          let flow1 = map.get(Math.ceil(data[i].partDeep))[9]
          flow1 = Number((partPulp1 / time * 60).toFixed(2))
          let flow2 = map.get(Math.ceil(data[i].partDeep))[10]
          flow2 = Number((partPulp2 / time * 60).toFixed(2))
          let flow3 = map.get(Math.ceil(data[i].partDeep))[11]
          flow3 = Number((partPulp3 / time * 60).toFixed(2))
          map.set(Math.ceil(data[i].partDeep), [partPulp1, partPulp2, partPulp3, totalPartPulp, totalPartAsh, partCurrent1, partCurrent2, partCurrent3, time, flow1, flow2, flow3])
        }
      }
      let reportData = []
      map.forEach((key) => {
        reportData.push(key)
      })
      let DownSpeed = []
      mapDownSpeed.forEach((key) => {
        DownSpeed.push(key)
      })
      let UpSpeed = []
      mapUpSpeed.forEach((key) => {
        UpSpeed.push(key)
      })
      this.downSpeedReport = DownSpeed
      this.upSpeedReport = UpSpeed.reverse()
      this.ReportData = reportData
      this.selectItem.totalAsh1 = ((this.selectItem.cumulativePulp1 / this.selectItem.cumulativePulp) * this.selectItem.cumulativeAsh).toFixed(2)
      this.selectItem.totalAsh2 = ((this.selectItem.cumulativePulp2 / this.selectItem.cumulativePulp) * this.selectItem.cumulativeAsh).toFixed(2)
      this.selectItem.totalAsh3 = ((this.selectItem.cumulativePulp3 / this.selectItem.cumulativePulp) * this.selectItem.cumulativeAsh).toFixed(2)
    },
    //历史列表导出选择框 选中发生变化
    selectionChange(e) {
      this.GFZreportData = e
      // deviceMode 0:压力 1:电流  partPulpPressure1
      let current = [] //电流
      let pressure = [] //压力
      for (let i = 0; i < e.length; i++) {
        if (e[i].deviceMode != 1) {
          pressure.push(e[i].data)
        } else {
          pressure.push([])
        }
        if (e[i].deviceMode == 1) {
          current.push(e[i].data)
        } else {
          current.push([])
        }
      }
      let dL = []
      let reportDL = []
      for (let i = 0; i < current.length; i++) {
        for (let j = 0; j < current[i].length; j++) {
          if (current[i].length == 0) {
            dL.push([])
          } else {
            if (current[i][j].partPulpPressure1 != 0) {
              dL.push(current[i][j].partPulpPressure1)
            }
            if (current[i][j].partPulpPressure2 != 0) {
              dL.push(current[i][j].partPulpPressure2)
            }
            if (current[i][j].partPulpPressure3 != 0) {
              dL.push(current[i][j].partPulpPressure3)
            }
            if (current[i][j].partPulpPressure4 != 0) {
              dL.push(current[i][j].partPulpPressure4)
            }
            if (current[i][j].partPulpPressure5 != 0) {
              dL.push(current[i][j].partPulpPressure5)
            }
          }
        }
        reportDL.push(dL)
        dL = []
      }
      let YL = []
      let reportYL = []
      for (let i = 0; i < pressure.length; i++) {
        for (let j = 0; j < pressure[i].length; j++) {
          if (pressure[i].length == 0) {
            YL.push([])
          } else {
            if (pressure[i][j].partPulpPressure1 != 0) {
              YL.push(pressure[i][j].partPulpPressure1)
            }
            if (pressure[i][j].partPulpPressure2 != 0) {
              YL.push(pressure[i][j].partPulpPressure2)
            }
            if (pressure[i][j].partPulpPressure3 != 0) {
              YL.push(pressure[i][j].partPulpPressure3)
            }
            if (pressure[i][j].partPulpPressure4 != 0) {
              YL.push(pressure[i][j].partPulpPressure4)
            }
            if (pressure[i][j].partPulpPressure5 != 0) {
              YL.push(pressure[i][j].partPulpPressure5)
            }
          }
        }
        reportYL.push(YL)
        YL = []
      }
      let totalYL = 0
      for (let i = 0; i < reportYL.length; i++) {
        for (let j = 0; j < reportYL[i].length; j++) {
          if (reportYL[i].length == 0) {
            totalYL = 0
          } else {
            totalYL += reportYL[i][j]
          }
        }
        this.GFZreportData[i].reportPressure = (totalYL / reportYL[i].length).toFixed(2)
        totalYL = 0
      }
      let totalDL = 0
      for (let i = 0; i < reportDL.length; i++) {
        for (let j = 0; j < reportDL[i].length; j++) {
          if (reportDL[i].length == 0) {
            totalDL = 0
          } else {
            totalDL += reportDL[i][j]
          }
        }
        this.GFZreportData[i].reportCurrent = (totalDL / reportDL[i].length).toFixed(2)
        totalDL = 0
      }
      if (e.length != 0) {
        this.dayTimeStart = moment(e[e.length - 1].beginTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.dayTimeEnd = moment(e[0].endTime * 1000).format('YYYY-MM-DD HH:mm:ss')
        this.repdevice = e[0].deviceKey
        this.waterCementRatio = e[0].waterCementRatio
      }

      if (e.length == this.tableData.length) {
        this.totalChecked = true;
      } else if (this.totalChecked) {
        this.totalChecked = false;
      }
    },
    //全选按钮
    handleCheckAllChange(val) {
      this.checkedFileds = val ? allField : [];
      this.isIndeterminate = false;
    },
    //单个选中
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.fieldNames.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fieldNames.length;
    },
    confirmShow() {
      this.tempCols = [];
      if (this.checkedFileds.length < 1) {
        this.$message.warning("请至少选择一项数据");
        this.dialogFormVisible = true;
      } else {
        for (var i = 0; i < this.fieldNames.length; i++) {
          for (var j = 0; j < this.checkedFileds.length; j++) {
            if (this.fieldNames[i].prop == this.checkedFileds[j]) {
              this.tempCols.push(this.fieldNames[i]);
            }
          }
        }
        this.cols = this.tempCols;
        this.dialogFormVisible = false;
      }
    },
    totalChange(e) {
      if (!e) {
        this.$refs.table.clearSelection();
      } else {
        this.$refs.table.clearSelection();
        this.$refs.table.toggleAllSelection();
      }
    },
  }
}
</script>
<style scoped>
/* 预警弹框样式 */
.upload-demo {
  margin-bottom: 10px;
}
.dialogBot .uploadBtn {
  text-align: right;
  margin-top: 10px;
}
/* 选择设备 */
.m-filter .item.picker {
  width: 20.25rem;
}
.m-tab-echartTable {
  position: relative;
}
.reportEchart {
  position: absolute;
  top: 0;
  left: -1000px;
  width: 800px;
  height: 800px;
}
.echarts_report {
  width: 100px;
  height: 40px;
  line-height: 40px;
  margin-top: 10px;
  border-radius: 20px;
  color: #fff;
  text-align: center;
  background: #409eff;
  float: left;
  margin-right: 10px;
  cursor: pointer;
}
.mapDiv {
  height: 100%;
  padding: 0.75rem 1.25rem;
}
.map {
  height: 100%;
}
.backplayBottom {
  background: #fff;
  border-radius: 10px;
  margin-top: 5px;
  height: 4rem;
  display: flex;
  justify-content: space-between;
}
.iconBack {
  color: #000;
  /* color: #409eff; */
  font-size: 26px;
  font-weight: bold;
  line-height: 4rem;
}
.progress_bar {
  width: 75%;
  margin-top: 25px;
}
.selectspeed {
  margin-top: 15px;
  width: 120px;
}
/* >>> .el-input--small .el-input__inner {
  color: #409eff;
} */

.play_back {
  height: 50%;
  display: flex;
  justify-content: space-between;
}
.m-statusinfo {
  background: #fff;
}

.play_col {
  background: #fff;
  margin: 5px;
  border-radius: 10px;
}
.backEchart {
  height: 18.3rem;
}
.m-datab1 {
  padding-top: 0.75rem;
}
.m-datab1 .top {
  margin-bottom: 0;
}

.picbox .data {
  bottom: 96%;
}
.picbox .databar {
  bottom: 10px;
}
/* 回放样式end */

.ul-detail li {
  width: 33%;
}
.el-checkbox {
  width: 20%;
}
.el-button--primary {
  margin-left: 25px;
}
.el-button--primary {
  margin-left: 25px;
  margin-right: 5px;
}
.m-filter .filter .btn {
  margin-left: 2.5rem;
}
.ul-detail .tit {
  min-width: 5em;
}
.ul-detail .s2 .tit {
  min-width: 8em;
}
.ul-detail .s3 .tit,
.ul-detail .s4 .tit {
  min-width: 10em;
}
/* 历史记录 详情 详细数据下的padding值 */
.ul-detail li {
  padding: 0 1.3rem 0 0;
}
.ul-detail li:first-child {
  padding-left: 1.6rem;
}
.m-filter .filter .btn[data-v-90e2c994] {
  margin-left: 20px;
}
</style>
